import {createSlice} from '@reduxjs/toolkit'
import initialState from "../initial-state";

const catalogSlice = createSlice({
    name: 'catalog',
    initialState: initialState.catalog,
    reducers: {}
});

export default catalogSlice.reducer
