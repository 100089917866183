import { AnalyticsVersion } from '~/helpers/analytics';
import Typewriter from '~/helpers/analytics/segment';
import { createAnalyticsSerializer } from './serialize';
/**
 * Client for interacting with Segment Analytics.
 *
 * The analytics.js snippet should be available via window.analytics.
 * You can install it by following instructions at: https://segment.com/docs/sources/website/analytics.js/quickstart/
 */
export const createAnalyticsClient = (config, oms) => {
    const serializerConfig = {
        tenant: config.tenant,
        storeName: config.storeName,
        storeID: config.storeID,
        version: AnalyticsVersion
    };

    const serializer= createAnalyticsSerializer(serializerConfig);

    const analytics = () => typeof window !== 'undefined' && window.analytics;

    const _identify = (...args) => {
        const a = analytics();
        if (a) a.identify(...args);
    };

    return {
        identify: (props, options, callback) => {
            const traits = serializer.identify(props);
            const userId = props.user ? traits.id : undefined;
            _identify(userId, traits, options, callback);
        },

        trackCartViewed: (props, options, callback) => {
            const trackingProps = serializer.cartViewed(props);
            Typewriter.cartViewed(trackingProps, options, callback);
        },

        trackCheckoutStarted: (props, options, callback) => {
            const trackingProps = serializer.checkoutStarted(props);
            Typewriter.checkoutStarted(trackingProps, options, callback);
        },

        trackCollectionClicked: (props, options, callback) => {
            const trackingProps = serializer.collectionClicked(props);
            Typewriter.collectionClicked(trackingProps, options, callback);
        },

        trackCouponApplied: (props, options, callback) => {
            const trackingProps = serializer.couponApplied(props);
            Typewriter.couponApplied(trackingProps, options, callback);
        },

        trackCouponDenied: (props, options, callback) => {
            const trackingProps = serializer.couponDenied(props);
            Typewriter.couponDenied(trackingProps, options, callback);
        },

        trackCouponEntered: (props, options, callback) => {
            const trackingProps = serializer.couponEntered(props);
            Typewriter.couponEntered(trackingProps, options, callback);
        },

        trackCouponRemoved: (props, options, callback) => {
            const trackingProps = serializer.couponRemoved(props);
            Typewriter.couponRemoved(trackingProps, options, callback);
        },

        trackEmailCaptured: (props, options, callback) => {
            const email = props.email;
            _identify({ email });
            const trackingProps = serializer.emailCaptured(props);
            Typewriter.emailCaptured(trackingProps, options, callback);
        },

        trackGiftCardApplied: (props, options, callback) => {
            const trackingProps = serializer.giftCardApplied(props);
            Typewriter.giftCardApplied(trackingProps, options, callback);
        },

        trackGiftCardDenied: (props, options, callback) => {
            const trackingProps = serializer.giftCardDenied(props);
            Typewriter.giftCardDenied(trackingProps, options, callback);
        },

        trackGiftCardEntered: (props, options, callback) => {
            const trackingProps = serializer.giftCardEntered(props);
            Typewriter.giftCardEntered(trackingProps, options, callback);
        },

        trackGiftCardRemoved: (props, options, callback) => {
            const trackingProps = serializer.giftCardRemoved(props);
            Typewriter.giftCardRemoved(trackingProps, options, callback);
        },

        trackProductAdded: (props, options, callback) => {
            const trackingProps = serializer.productChanged(props);
            Typewriter.productAdded(trackingProps, options, callback);
        },

        trackProductClicked: async (props, options, callback) => {
            const { productHandle, order, user, placement, position } = props;
            const product = await oms.getProduct(productHandle);
            const trackingProps = serializer.productClicked({ product, order, user, placement, position });
            Typewriter.productClicked(trackingProps, options, callback);
        },

        trackVariantClicked: async (props, options, callback) => {
            const { variantId, order, user } = props;
            const variant = await oms.getVariant(variantId);
            const trackingProps = serializer.variantClicked({ variant, order, user });
            Typewriter.variantClicked(trackingProps, options, callback);
        },

        trackProductRemoved: (props, options, callback) => {
            const trackingProps = serializer.productChanged(props);
            Typewriter.productRemoved(trackingProps, options, callback);
        },

        trackProductUpdated: (props, options, callback) => {
            const trackingProps = serializer.productChanged(props);
            Typewriter.productUpdated(trackingProps, options, callback);
        },

        trackProductListFiltered: (props, options, callback) => {
            const trackingProps = serializer.productListFiltered(props);
            Typewriter.productListFiltered(trackingProps, options, callback);
        },

        trackProductListViewed: (props, options, callback) => {
            const trackingProps = serializer.productListViewed(props);
            Typewriter.productListViewed(trackingProps, options, callback);
        },

        trackProductReviewed: (props, options, callback) => {
            const trackingProps = serializer.productReviewed(props);
            Typewriter.productReviewed(trackingProps, options, callback);
        },

        trackProductViewed: async (props, options, callback) => {
            const { productHandle, order, user } = props;
            const product = await oms.getProduct(productHandle);
            const trackingProps = serializer.productViewed({ product, order, user });
            Typewriter.productViewed(trackingProps, options, callback);
        },

        trackPromotionClicked: (props, options, callback) => {
            const trackingProps = serializer.promotionClicked(props);
            Typewriter.promotionClicked(trackingProps, options, callback);
        },

        trackPromotionViewed: (props, options, callback) => {
            const trackingProps = serializer.promotionViewed(props);
            Typewriter.promotionViewed(trackingProps, options, callback);
        },

        trackStockRequestCreated: async (props, options, callback) => {
            const { email, productHandle } = props;
            _identify({ email });
            const product = await oms.getProduct(productHandle);
            const trackingProps = serializer.stockRequestCreated({ email, product });
            Typewriter.stockRequestCreated(trackingProps, options, callback);
        },

        trackUserAddressAdded: (props, options, callback) => {
            const trackingProps = serializer.userAddressAdded(props);
            Typewriter.userAddressAdded(trackingProps, options, callback);
        },

        trackUserAddressRemoved: (props, options, callback) => {
            const trackingProps = serializer.userAddressRemoved(props);
            Typewriter.userAddressRemoved(trackingProps, options, callback);
        },

        trackUserAddressUpdated: (props, options, callback) => {
            const trackingProps = serializer.userAddressUpdated(props);
            Typewriter.userAddressUpdated(trackingProps, options, callback);
        },

        trackUserPasswordResetCompleted: (props, options, callback) => {
            const trackingProps = serializer.userPasswordResetCompleted(props);
            Typewriter.userPasswordResetCompleted(trackingProps, options, callback);
        },

        trackUserPasswordResetStarted: (props, options, callback) => {
            const trackingProps = serializer.userPasswordResetStarted(props);
            Typewriter.userPasswordResetStarted(trackingProps, options, callback);
        },

        trackUserSignedIn: (props, options, callback) => {
            const trackingProps = serializer.userSignedIn(props);
            Typewriter.userSignedIn(trackingProps, options, callback);
        },

        trackUserSignedOut: (props, options, callback) => {
            const trackingProps = serializer.userSignedOut(props);
            Typewriter.userSignedOut(trackingProps, options, callback);
        },

        trackUserSignedUp: (props, options, callback) => {
            const trackingProps = serializer.userSignedUp(props);
            Typewriter.userSignedUp(trackingProps, options, callback);
        },

        trackProductDetailsClicked: async (props, options, callback) => {
            const { title, productHandle } = props;
            const product = await oms.getProduct(productHandle);
            const trackingProps = serializer.productDetailsClicked({ title, product });
            Typewriter.productDetailsClicked(trackingProps, options, callback);
        },

        trackNavigationClicked: (props, options, callback) => {
            const trackingProps = serializer.navigationClicked(props);
            Typewriter.navigationClicked(trackingProps, options, callback);
        }
    };
};
