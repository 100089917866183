import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { cartReducer } from './cart'
import { catalogReducer } from './catalog'
import { authReducer } from './auth'
import { userReducer } from './user'
import defaultInitialState from './initial-state'
import {
    logger as loggerMiddleware,
    persist as persistMiddleware,
    session as sessionMiddleware,
} from './middleware'
import api from '~/services/api'
import initializeState from './init'

const isBrowser = typeof window !== 'undefined'

const store = (reducer = {}, preloadedState = {}, middleware = []) => {
    const storeReducer = combineReducers({
        cart: cartReducer,
        catalog: catalogReducer,
        auth: authReducer,
        user: userReducer,
        ...reducer
    })

    const storePreloadedState = {
        ...defaultInitialState,
        ...preloadedState
    }

    const store = configureStore({
        reducer: storeReducer,
        preloadedState: storePreloadedState,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                thunk: {
                    extraArgument: { api }
                },
            })
                .concat(persistMiddleware, loggerMiddleware, sessionMiddleware, ...middleware),
    });

    return store
}

export default store
