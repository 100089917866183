const defineProperty = (target, key, value) => {
    Object.defineProperty(target, key, {
        value,
        writable: true,
        enumerable: true,
        configurable: true
    });
};

const ownKeys = (object, enumerableOnly) => {
    let keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        let symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(sym => Object.getOwnPropertyDescriptor(object, sym).enumerable);
        }
        keys.push(...symbols);
    }
    return keys;
};

const objectSpread = (target, ...sources) => {
    sources.forEach(source => {
        if (source !== null) {
            ownKeys(Object(source), true).forEach(key => {
                defineProperty(target, key, source[key]);
            });
        }
    });
    return target;
};

const decodeShopifyId = (id, preface) => {
    if (id && id.includes(preface)) {
        return id.replace(preface, '').split('?')[0];
    }
    try {
        const decoded = Buffer.from(id, 'base64').toString('utf-8');
        if (decoded.includes(preface)) {
            return decoded.replace(preface, '').split('?')[0];
        }
    } catch (error) {
        return id;
    }
    return id;
};

const decodeProductId = (id) => decodeShopifyId(id, 'gid://shopify/Product/');
const decodeProductVariantId = (id) => decodeShopifyId(id, 'gid://shopify/ProductVariant/');
const decodeAddressId = (id) => decodeShopifyId(id, 'gid://shopify/MailingAddress/');
const decodeUserId = (id) => decodeShopifyId(id, 'gid://shopify/Customer/');

export const serializeUser = (user) => ({
    user_id: decodeUserId(user.id),
    user_email: user.email,
    user_first_name: user.firstName,
    user_last_name: user.lastName,
    user_display_name: user.displayName,
    user_phone: user.phone
});

export const serializeAddress = (address, isDefault) => ({
    address_id: decodeAddressId(address.id),
    address_name: address.name,
    address_first_name: address.firstName,
    address_last_name: address.lastName,
    address_address_1: address.address1,
    address_address_2: address.address2,
    address_city: address.city,
    address_state: address.province,
    address_country: address.country,
    address_company: address.company,
    address_zip: address.zip,
    address_phone: address.phone,
    address_is_default: isDefault
});

export const serializeGiftCard = (appliedGiftCard) => ({
    gift_card_id: appliedGiftCard.id,
    gift_card_last_characters: appliedGiftCard.lastCharacters
});

export const serializeVariant = (variant) => {
    let description = null;
    if (variant.selectedOptions.length > 0 && variant.selectedOptions[0].value !== 'Default Title') {
        description = variant.selectedOptions.map((o) => `${o.name}: ${o.value}`).join(', ');
    }
    const imageUrl = variant.image && variant.image.originalSrc ? variant.image.originalSrc : null;
    return {
        variant_id: decodeProductVariantId(variant.id),
        variant_sku: variant.sku,
        variant_name: variant.title,
        variant_price: variant.priceV2 ? variant.priceV2.amount : undefined,
        ...(imageUrl ? { variant_image_url: imageUrl } : {}),
        ...(description ? { variant_description: description } : {})
    };
};

export const serializeProductAndVariant = (product, variant, quantity, placement, position) => {
    const variantProps = serializeVariant(variant);
    const imageUrl = product.images && product.images.length ? product.images[0].originalSrc : undefined;
    return {
        product_id: decodeProductId(product.id),
        product_image_url: imageUrl,
        product_name: product.title,
        product_description: product.description,
        product_variant_id: variantProps.variant_id,
        product_variant_sku: variantProps.variant_sku,
        product_variant_name: variantProps.variant_name,
        product_variant_price: variantProps.variant_price,
        product_slug: product.handle,
        ...(typeof quantity === 'number' ? { product_quantity: quantity, quantity } : {}),
        ...(placement ? { product_placement: placement } : {}),
        ...(typeof position === 'number' ? { product_position: position } : {}),
        name: product.title,
        sku: variantProps.variant_sku,
        variant: variantProps.variant_name,
        price: Number(variantProps.variant_price) || 0
    };
};

export const serializeProduct = (product, placement, position) => {
    const imageUrl = product.images && product.images.length ? product.images[0].originalSrc : undefined;
    return {
        product_id: decodeProductId(product.id),
        product_image_url: imageUrl,
        product_name: product.title,
        product_description: product.description,
        product_slug: product.handle,
        ...(placement ? { product_placement: placement } : {}),
        ...(typeof position === 'number' ? { product_position: position } : {}),
        name: product.title
    };
};

export const serializeLineItem = (lineItem) => ({
    ...serializeProductAndVariant(lineItem.variant.product, lineItem.variant),
    quantity: lineItem.quantity
});

export const serializeOrderProducts = (order) => order.lineItems.map(serializeLineItem);

export const serializeDiscount = (discountApplication) => ({
    discount_coupon_code: discountApplication.code
});

export const serializeMeta = (config) => ({
    ownership: {
        tenant_id: config.tenant,
        store_id: config.storeID
    },
    store: {
        domain: config.storeName
    },
    platform: {
        name: 'Shopify',
        type: 'web'
    },
    version: {
        major: config.version.major,
        minor: config.version.minor,
        patch: config.version.patch
    }
});

export const serializeUserTraits = (user) => ({
    address: user.defaultAddress ? {
        address_id: decodeAddressId(user.defaultAddress.id),
        ...user.defaultAddress,
        address_is_default: true
    } : {},
    company: user.defaultAddress ? { name: user.defaultAddress.company } : {},
    created_at: user.createdAt,
    id: decodeUserId(user.id),
    ...user
});

export const serialize = (data) => JSON.parse(JSON.stringify(data, (_key, value) => value === null ? undefined : value));

export const createAnalyticsSerializer = (config) => {
    const meta = serializeMeta(config);

    return {
        identify: ({ traits, user }) => serialize({ ...traits, ...(user ? serializeUserTraits(user) : {}) }),

        cartViewed: ({ order, user }) => serialize({
            meta,
            products: serializeOrderProducts(order),
            ...(user ? serializeUser(user) : {}),
            currency: order.currencyCode
        }),

        checkoutStarted: ({ order, user }) => serialize({
            meta,
            products: serializeOrderProducts(order),
            ...(user ? serializeUser(user) : {})
        }),

        collectionClicked: ({ id, description, imageUrl, slug, title }) => serialize({
            collection_id: id,
            collection_description: description,
            collection_image_url: imageUrl,
            collection_slug: slug,
            collection_title: title,
            meta
        }),

        couponApplied: ({ promoCode }) => serialize({
            coupon_name: promoCode,
            meta
        }),

        couponDenied: ({ promoCode, reason }) => serialize({
            coupon_name: promoCode,
            reason,
            meta
        }),

        couponEntered: ({ promoCode }) => serialize({
            coupon_name: promoCode,
            meta
        }),

        couponRemoved: ({ promoCode }) => serialize({
            coupon_name: promoCode,
            meta
        }),

        emailCaptured: ({ email, placement }) => serialize({
            email,
            placement_component: placement.component,
            placement_page: placement.page,
            meta
        }),

        giftCardApplied: ({ giftCard }) => serialize({
            gift_card_id: giftCard.id,
            gift_card_last_characters: giftCard.lastCharacters,
            meta
        }),

        giftCardDenied: ({ lastCharacters, reason }) => serialize({
            gift_card_last_characters: lastCharacters,
            reason,
            meta
        }),

        giftCardEntered: ({ lastCharacters }) => serialize({
            gift_card_last_characters: lastCharacters,
            meta
        }),

        giftCardRemoved: ({ giftCard }) => serialize({
            gift_card_id: giftCard.id,
            gift_card_last_characters: giftCard.lastCharacters,
            meta
        }),

        productChanged: ({ lineItemId, order, quantity, user, placement, position }) => {
            const lineItem = order.lineItems.find(item => item.id === lineItemId);
            return serialize({
                ...serializeProductAndVariant(lineItem.variant.product, lineItem.variant, quantity, placement, position),
                ...(user ? serializeUser(user) : {}),
                meta,
                currency: order.currencyCode
            });
        },

        productViewed: ({ product, user }) => serialize({
            ...serializeProduct(product),
            ...(user ? serializeUser(user) : {}),
            meta
        }),

        productClicked: ({ product, user, placement, position }) => serialize({
            ...serializeProduct(product, placement, position),
            ...(user ? serializeUser(user) : {}),
            meta
        }),

        variantClicked: ({ variant, user }) => serialize({
            ...serializeVariant(variant),
            ...(user ? serializeUser(user) : {}),
            meta
        }),

        productListFiltered: ({ list, filter, sort }) => serialize({
            product_list_category: list.category,
            product_list_filter_type: filter.type,
            product_list_filter_value: filter.value,
            product_list_id: list.id,
            product_list_sort_type: sort.type,
            product_list_sort_value: sort.value,
            meta
        }),

        productListViewed: ({ list }) => serialize({
            product_list_category: list.category,
            product_list_id: list.id,
            meta
        }),

        productReviewed: ({ email, productId, review, variantId }) => serialize({
            product_id: productId,
            review_body: review.body,
            review_id: review.id,
            review_rating: review.rating,
            user_email: email,
            variant_id: variantId,
            meta
        }),

        promotionClicked: ({ promotion, user }) => serialize({
            promotion_creative: promotion.creative,
            promotion_id: promotion.id,
            promotion_name: promotion.name,
            promotion_position: promotion.position,
            ...(user ? serializeUser(user) : {}),
            meta
        }),

        promotionViewed: ({ promotion, user }) => serialize({
            promotion_creative: promotion.creative,
            promotion_id: promotion.id,
            promotion_name: promotion.name,
            promotion_position: promotion.position,
            ...(user ? serializeUser(user) : {}),
            meta
        }),

        stockRequestCreated: ({ email, product }) => serialize({
            email,
            ...serializeProduct(product),
            meta
        }),

        userSignedIn: ({ user }) => serialize({
            ...serializeUser(user),
            meta
        }),

        userSignedOut: ({ user }) => serialize({
            ...serializeUser(user),
            meta
        }),

        userSignedUp: ({ referralIdentifierId, signupType, user }) => serialize({
            ...serializeUser(user),
            referral_identifier_id: referralIdentifierId,
            signup_type: signupType,
            meta
        }),

        userAddressAdded: ({ address, isDefault, user }) => serialize({
            ...serializeAddress(address, isDefault),
            ...serializeUser(user),
            meta
        }),

        userAddressRemoved: ({ addressId, isDefault, user }) => serialize({
            address_id: addressId,
            address_is_default: isDefault,
            ...serializeUser(user),
            meta
        }),

        userAddressUpdated: ({ address, isDefault, user }) => serialize({
            ...serializeAddress(address, isDefault),
            ...serializeUser(user),
            meta
        }),

        userPasswordResetCompleted: ({ user }) => serialize({
            ...serializeUser(user),
            meta
        }),

        userPasswordResetStarted: ({ email }) => serialize({
            email,
            meta
        }),

        productDetailsClicked: ({ title, product }) => serialize({
            title,
            ...serializeProduct(product),
            meta
        }),

        navigationClicked: ({ title, url, placement }) => serialize({
            navigation_title: title,
            label: title,
            navigation_url: url,
            ...(placement ? { navigation_placement: placement, category: placement } : {}),
            meta
        })
    };
};
