export const AUTH_LOGIN_REQUEST = 'AUTH_LOGIN_REQUEST'
export const AUTH_LOGIN_RESPONSE = 'AUTH_LOGIN_RESPONSE'
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS'
export const AUTH_LOGIN_ERROR = 'AUTH_LOGIN_ERROR'
export const AUTH_LOGOUT_REQUEST = 'AUTH_LOGOUT_REQUEST'
export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS'
export const AUTH_LOGOUT_ERROR = 'AUTH_LOGOUT_ERROR'
export const AUTH_IDENTIFY_REQUEST = 'AUTH_IDENTIFY_REQUEST'
export const AUTH_IDENTIFY_SUCCESS = 'AUTH_IDENTIFY_SUCCESS'
export const AUTH_IDENTIFY_ERROR = 'AUTH_IDENTIFY_ERROR'
export const AUTH_REGISTER_REQUEST = 'AUTH_REGISTER_REQUEST'
export const AUTH_REGISTER_SUCCESS = 'AUTH_REGISTER_SUCCESS'
export const AUTH_REGISTER_ERROR = 'AUTH_REGISTER_ERROR'
export const AUTH_RECOVER_ERROR = 'AUTH_REGISTER_ERROR'
export const AUTH_RECOVER_REQUEST = 'AUTH_RECOVER_REQUEST'
export const AUTH_RECOVER_SUCCESS = 'AUTH_RECOVER_SUCCESS'
export const AUTH_RESET_PASSWORD_ERROR = 'AUTH_RESET_PASSWORD_ERROR'
export const AUTH_RESET_PASSWORD_REQUEST = 'AUTH_RESET_PASSWORD_REQUEST'
export const AUTH_RESET_PASSWORD_SUCCESS = 'AUTH_RESET_PASSWORD_SUCCESS'
export const CLEAR_SESSION = 'CLEAR_SESSION'
export const LOAD_CART_REQUEST = 'LOAD_CART_REQUEST'
export const LOAD_CART_SUCCESS = 'LOAD_CART_SUCCESS'
export const LOAD_CART_ERROR = 'LOAD_CART_ERROR'
export const ADD_TO_CART_REQUEST = 'ADD_TO_CART_REQUEST'
export const ADD_TO_CART_SUCCESS = 'ADD_TO_CART_SUCCESS'
export const ADD_TO_CART_ERROR = 'ADD_TO_CART_ERROR'
export const MODIFY_CART_QUANTITY_REQUEST = 'MODIFY_CART_QUANTITY_REQUEST'
export const MODIFY_CART_QUANTITY_SUCCESS = 'MODIFY_CART_QUANTITY_SUCCESS'
export const MODIFY_CART_QUANTITY_ERROR = 'MODIFY_CART_QUANTITY_ERROR'
export const MODIFY_CART_GIFT_CARDS_REQUEST = 'MODIFY_CART_GIFT_CARDS_REQUEST'
export const MODIFY_CART_GIFT_CARDS_SUCCESS = 'MODIFY_CART_GIFT_CARDS_SUCCESS'
export const MODIFY_CART_GIFT_CARDS_ERROR = 'MODIFY_CART_GIFT_CARDS_ERROR'
export const REMOVE_FROM_CART_REQUEST = 'REMOVE_FROM_CART_REQUEST'
export const REMOVE_FROM_CART_SUCCESS = 'REMOVE_FROM_CART_SUCCESS'
export const REMOVE_FROM_CART_ERROR = 'REMOVE_FROM_CART_ERROR'
export const FORGET_CART = 'FORGET_CART'
export const CHECKOUT_REQUEST = 'CHECKOUT_REQUEST'
export const CHECKOUT_SUCCESS = 'CHECKOUT_SUCCESS'
export const CHECKOUT_ERROR = 'CHECKOUT_ERROR'
export const FINALIZE_CHECKOUT_REQUEST = 'FINALIZE_CHECKOUT_REQUEST'
export const FINALIZE_CHECKOUT_SUCCESS = 'FINALIZE_CHECKOUT_SUCCESS'
export const FINALIZE_CHECKOUT_ERROR = 'FINALIZE_CHECKOUT_ERROR'
export const ADD_PROMO_CODE_REQUEST = 'ADD_PROMO_CODE_REQUEST'
export const ADD_PROMO_CODE_SUCCESS = 'ADD_PROMO_CODE_SUCCESS'
export const ADD_PROMO_CODE_ERROR = 'ADD_PROMO_CODE_ERROR'
export const REMOVE_PROMO_CODE_REQUEST = 'REMOVE_PROMO_CODE_REQUEST'
export const REMOVE_PROMO_CODE_SUCCESS = 'REMOVE_PROMO_CODE_SUCCESS'
export const REMOVE_PROMO_CODE_ERROR = 'REMOVE_PROMO_CODE_ERROR'
export const APPLY_GIFT_CARD_REQUEST = 'APPLY_GIFT_CARD_REQUEST'
export const APPLY_GIFT_CARD_SUCCESS = 'APPLY_GIFT_CARD_SUCCESS'
export const APPLY_GIFT_CARD_ERROR = 'APPLY_GIFT_CARD_ERROR'
export const REMOVE_GIFT_CARD_REQUEST = 'REMOVE_GIFT_CARD_REQUEST'
export const REMOVE_GIFT_CARD_SUCCESS = 'REMOVE_GIFT_CARD_SUCCESS'
export const REMOVE_GIFT_CARD_ERROR = 'REMOVE_GIFT_CARD_ERROR'
export const TRACK_CLICK_PRODUCT = 'TRACK_CLICK_PRODUCT'
export const TRACK_CLICK_COLLECTION = 'TRACK_CLICK_COLLECTION'
export const TRACK_CREATE_STOCK_REQUEST = 'TRACK_CREATE_STOCK_REQUEST'
export const TRACK_VIEW_CART = 'TRACK_VIEW_CART'
export const INITIALIZE_STATE_FROM_STORAGE = 'INITIALIZE_STATE_FROM_STORAGE'
export const MODIFY_CART_REQUEST = 'MODIFY_CART_REQUEST'
export const MODIFY_CART_SUCCESS = 'MODIFY_CART_SUCCESS'
export const MODIFY_CART_ERROR = 'MODIFY_CART_ERROR'
export const ASSOCIATE_CART_WITH_USER_REQUEST =
    'ASSOCIATE_CART_WITH_USER_REQUEST'
export const ASSOCIATE_CART_WITH_USER_SUCCESS =
    'ASSOCIATE_CART_WITH_USER_SUCCESS'
export const ASSOCIATE_CART_WITH_USER_ERROR = 'ASSOCIATE_CART_WITH_USER_ERROR'
export const LOAD_USER_REQUEST = 'LOAD_USER_REQUEST'
export const LOAD_USER_SUCCESS = 'LOAD_USER_SUCCESS'
export const LOAD_USER_ERROR = 'LOAD_USER_ERROR'
export const CREATE_USER_ADDRESS_REQUEST = 'CREATE_USER_ADDRESS_REQUEST'
export const CREATE_USER_ADDRESS_SUCCESS = 'CREATE_USER_ADDRESS_SUCCESS'
export const CREATE_USER_ADDRESS_ERROR = 'CREATE_USER_ADDRESS_ERROR'
export const MODIFY_USER_ADDRESS_REQUEST = 'MODIFY_USER_ADDRESS_REQUEST'
export const MODIFY_USER_ADDRESS_SUCCESS = 'MODIFY_USER_ADDRESS_SUCCESS'
export const MODIFY_USER_ADDRESS_ERROR = 'MODIFY_USER_ADDRESS_ERROR'
export const REMOVE_USER_ADDRESS_REQUEST = 'REMOVE_USER_ADDRESS_REQUEST'
export const REMOVE_USER_ADDRESS_SUCCESS = 'REMOVE_USER_ADDRESS_SUCCESS'
export const REMOVE_USER_ADDRESS_ERROR = 'REMOVE_USER_ADDRESS_ERROR'
export const MODIFY_USER_DEFAULT_ADDRESS_REQUEST =
    'MODIFY_USER_DEFAULT_ADDRESS_REQUEST'
export const MODIFY_USER_DEFAULT_ADDRESS_SUCCESS =
    'MODIFY_USER_DEFAULT_ADDRESS_SUCCESS'
export const MODIFY_USER_DEFAULT_ADDRESS_ERROR =
    'MODIFY_USER_DEFAULT_ADDRESS_ERROR'
export const MODIFY_CART_LINE_ITEM_REQUEST = 'MODIFY_CART_LINE_ITEM_REQUEST'
export const MODIFY_CART_LINE_ITEM_SUCCESS = 'MODIFY_CART_LINE_ITEM_SUCCESS'
export const MODIFY_CART_LINE_ITEM_ERROR = 'MODIFY_CART_LINE_ITEM_ERROR'

const status = {
    Idle: 'idle',
    Initial: 'initial',
    Failed: 'failed',
    Loading: 'loading',
    Succeeded: 'succeeded',
}

export const AuthStatus = status
export const UserStatus = status