import { createAsyncThunk } from '@reduxjs/toolkit';

export const loadUser = createAsyncThunk(
    'user/loadUser',
    async (_, { getState, extra }) => {
        const { auth } = getState();
        const response = await extra.api.getUser(auth.token);
        return response;
    }
);

export const modifyUserAddress = createAsyncThunk(
    'user/modifyUserAddress',
    async ({ addressId, address, isDefaultAddress = false }, { getState, extra }) => {
        const { auth } = getState();
        const response = await extra.api.updateUserAddress(
            auth.token,
            addressId,
            address,
            isDefaultAddress
        );
        return response;
    }
);

export const modifyUserDefaultAddress = createAsyncThunk(
    'user/modifyUserDefaultAddress',
    async (addressId, { getState, extra }) => {
        const { auth } = getState();
        const response = await extra.api.updateUserDefaultAddress(auth.token, addressId);
        return response;
    }
);

export const removeUserAddress = createAsyncThunk(
    'user/removeUserAddress',
    async (addressId, { getState, extra }) => {
        const { auth } = getState();
        const response = await extra.api.removeUserAddress(auth.token, addressId);
        return response;
    }
);

export const createUserAddress = createAsyncThunk(
    'user/createUserAddress',
    async ({ address, isDefaultAddress = false }, { getState, extra }) => {
        const { auth } = getState();
        const response = await extra.api.createUserAddress(auth.token, address, isDefaultAddress);
        return response;
    }
);

