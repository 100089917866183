import React from 'react'
import ReactDOM from "react-dom/client"
import propTypes from 'prop-types'
import {
    GrowthBook, GrowthBookProvider
} from '@growthbook/growthbook-react'
import ConsentManagerWrapper from './src/components/Segment/ConsentManager'
import {
    isBrowser, isMobile, isTablet
} from 'react-device-detect';
import './static/css/swiper.css'
import './static/fonts/fonts.css'
import {
    createCookie, createUTMCookie, create_UUID
} from './src/utils/cookie'
import ppm from './src/utils/ppm'
import './src/utils/polyfills'
import ReduxProvider from "./src/store/context/redux-provider";
import WrapWithAllProviders from "./src/store/context/wrap-with-all-providers"


global.Buffer = global.Buffer || require('buffer').Buffer

let visitor_id;
if (isBrowser) {
    visitor_id = localStorage.getItem("growthbook_visitor_id");

    if (!visitor_id) {
        visitor_id = create_UUID();
        localStorage.setItem('growthbook_visitor_id', visitor_id);
    }
}

let deviceType = 'desktop'

if (isMobile) {
    deviceType = 'mobile'
} else if (isTablet) {
    deviceType = 'tablet'
}

const growthbook = new GrowthBook({
    apiHost: process.env.GATSBY_GROWTHBOOK_API_HOST,
    clientKey: process.env.GATSBY_GROWTHBOOK_CLIENT_KEY,
    enableDevMode: process.env.NODE_ENV === 'development',
    attributes: {
        id: visitor_id, isMobileScreenSize: isBrowser ? document.body.clientWidth < 992 : false, deviceType
    },
    trackingCallback: (experiment, result) => {
        if(isBrowser){
        const params = new URLSearchParams(window.location.search)
        const version = params.get(`gb-${experiment.key}`)

        if (version) return

        localStorage.setItem(`growthbook_experiment_${experiment.key}`, result.key)

        window.analytics.track('Experiment Viewed', {
            experimentId: experiment.key, variationId: result.key
        })
    }
    }
})


/**
 * Wraps the apps page element with Segment's Consent Manager.
 *
 * See:
 * https://github.com/segmentio/consent-manager
 * https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#wrapPageElement
 * https://github.com/segmentio/consent-manager/issues/10#issuecomment-679896358
 */

export const wrapRootElement = ({element}) => {
    return (
        <ReduxProvider>
            {element}
        </ReduxProvider>
    )
}
export const wrapPageElement = ({element}) => {
    return (
            // <ConsentManagerWrapper>
                <GrowthBookProvider growthbook={growthbook}>
                    {element}
                </GrowthBookProvider>
            // </ConsentManagerWrapper>
    )
}

wrapRootElement.propTypes = {
    element: propTypes.element.isRequired
}

wrapPageElement.propTypes = {
    element: propTypes.element.isRequired
}

const addScript = (url, props) => {
    let script = document.createElement('script')
    script.src = url
    script.async = true

    if (props && props.id) script.id = props.id
    if (props && props.class) script.className = props.class
    if (props && props['data-id']) script.dataset.id = props['data-id']
    if (props && props.onload) script.onload = props.onload

    document.body.appendChild(script)
}

const addImage = (src) => {
    let image = document.createElement('IMG')
    image.setAttribute("src", src);
    image.setAttribute("height", "0");
    image.setAttribute("width", "0");
    image.setAttribute("alt", "");
    image.style.setProperty("display", "none");
    image.style.setProperty("position", "absolute");
    image.style.setProperty("visibility", "hidden");
    document.body.appendChild(image)
}

const addMetaTag = (name, content) => {
    let meta = document.createElement('meta')
    meta.name = name
    meta.content = content
    document.head.appendChild(meta)
}

const getParam = param => new URLSearchParams(window.location.search).get(param)

export const onClientEntry = () => {
    window.ju_num = "5CD54B27-54F9-4336-8F90-04A9550AA39D";

    window.onload = () => {
        const html = document.querySelector('html')
        html.style.scrollPaddingTop = '118px'

        // const onGladlyLoad = () => {
        //     window.gladlyConfig = {
        //         appId: 'tranchnet.com'
        //     };
        // }
        //
        // addScript('https://cdn.gladly.com/chat-sdk/widget.js', {
        //     onload: onGladlyLoad
        // })



        // addScript('https://embed.tawk.to/65fd9d95a0c6737bd123b0f1/1hpjav4l5')


        if (process.env.GATSBY_OCTANE_AI_KEY) {
            addScript(`https://app.octaneai.com/${process.env.GATSBY_OCTANE_AI_KEY}/quiz.js`)
        }
        if (process.env.GATSBY_OKENDO_SUBSCRIBER_ID) {
            addMetaTag('oke:subscriber_id', process.env.GATSBY_OKENDO_SUBSCRIBER_ID)
            addScript('https://d3hw6dc1ow8pp2.cloudfront.net/reviews-widget-plus/js/okendo-reviews.js')
        }
        // if (process.env.GATSBY_TALKABLE_SITE_ID) {
        //     const onTalkableLoad = () => {
        //         const paramPage = getParam('traffic_source')
        //         const detectedCpid = getParam('cpid')
        //
        //         if (detectedCpid !== null) {
        //             sessionStorage.setItem('cpid', detectedCpid)
        //         }
        //
        //         window._talkableq = window._talkableq || []
        //
        //         window._talkableq.push(['authenticate_customer', {
        //             email: '', first_name: '', last_name: '', traffic_source: paramPage
        //         }])
        //     }
        //     addScript(`//d2jjzw81hqbuqv.cloudfront.net/integration/clients/${process.env.GATSBY_TALKABLE_SITE_ID}.min.js`, {
        //         onload: onTalkableLoad
        //     })
        // }

        // const activateBEN = () => {
        //     const o = window.tdl = window.tdl || [];
        //     if (!o.invoked) {
        //         o.invoked = !0
        //         o.methods = ["init", "identify", "convert"]
        //         o.factory = function (n) {
        //             return function () {
        //                 const e = Array.prototype.slice.call(arguments);
        //                 return e.unshift(n), o.push(e), o
        //             }
        //         }
        //     }
        //     for (var e = 0; e < o.methods.length; e++) {
        //         var n = o.methods[e];
        //         o[n] = o.factory(n)
        //     }
        //     addScript('https://js.go2sdk.com/v2/tune.js')
        //     o.domain = "https://www.inflcr.co"
        //     tdl.identify()
        // }
        // activateBEN();

        // const onGrinLoad = () => {
        //     window.Grin = window.Grin || (window.Grin = [])
        // }
        // addScript('https://d38xvr37kwwhcm.cloudfront.net/js/grin-sdk.js', {
        //     onload: onGrinLoad
        // })

        // const tvSciLoad = () => {
        //     const {
        //         location: curPage
        //     } = window
        //     const base = 'https://pix.pub/t.png?&t='
        //     const date = (new Date).getTime()
        //     const id = '&l=tvscientific-pix-o-5698b3ee-b682-4f36-b459-0dfdfcf54e29&u3='
        //     const encoded = encodeURIComponent(curPage.href)
        //     const URL = base + date + id + encoded
        //     addImage(URL)
        // }
        // tvSciLoad()
        //
        // const onPodcornLoad = () => {
        //     window.podcornDataLayer = window.podcornDataLayer || []
        //
        //     function podcorn() {
        //         window.podcornDataLayer.push(arguments);
        //     }
        //
        //     podcorn('js', new Date());
        //     podcorn('config', '845a484c-299e-4ae4-bda7-57eaa0f820c4');
        // }

        // addScript('https://behaviour.podcorn.com/js/app.js?id=845a484c-299e-4ae4-bda7-57eaa0f820c4', {
        //     onload: onPodcornLoad
        // })
        //
        // addScript('https://cdn.jst.ai/vck.js')

        // const onSequelSurround = (b, a) => {
        //     b.saq || (a = b.saq = function () {
        //         a.callMethod ? a.callMethod.apply(a, arguments) : a.queue.push(arguments)
        //     }, b._saq || (b._saq = a), a.push = a, a.loaded = !0, a.version = "1.0", a.queue = [])
        //     saq("ts", "opyUV1K84JCL5SAOTB-wbg");
        // }
        //
        // addScript('https://contentdsp.com/events.js', {
        //     onload: onSequelSurround(window)
        // })

        // const onRevRoll = () => {
        //     const l = 'dataLayer'
        //     window[l] = window[l] || []
        //     window[l].push({
        //         'gtm.start': new Date().getTime(), event: 'gtm.js'
        //     })
        // }
        // addScript(`https://ss.tranchnet.com/pqbftcll.js?id=GTM-TTZRC24`, {
        //     onload: onRevRoll()
        // })

        // const onTrafficGuard = () => {
        //     window.dataTrafficGuard = window.dataTrafficGuard || [];
        //     window.dataTrafficGuard.push(['property', 'tg-009547-001']);
        //     window.dataTrafficGuard.push(['event', 'pageview', {
        //         "afp": true
        //     }]);
        // }
        // addScript('//tgtag.io/tg.js?pid=tg-009547-001', {
        //     onload: onTrafficGuard(window)
        // })

        addScript('https://sdk.postscript.io/sdk.bundle.js?shopId=323190')

        createCookie('FPC', 7 * 24)
        createUTMCookie('utm_source', 7 * 24)
        createUTMCookie('utm_campaign', 7 * 24)
        createUTMCookie('utm_medium', 7 * 24)
        // ppm('visit')
    }
}
