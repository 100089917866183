class OMSError extends Error {
    constructor(error) {
        super(typeof error === 'string' ? error : error.message);
        this.name = 'OMSError';
        this.message = typeof error === 'string' ? error : error.message;
        this.code = typeof error === 'object' && error.code || getCodeFromMessage(this.message);

        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, OMSError);
        }
    }
}

const messageCodes = {
    UNAUTHORIZED: ['access token does not exist', 'access denied']
};

function getCodeFromMessage(message) {
    const matchingCode = Object.keys(messageCodes).find(code =>
        messageCodes[code].some(m => message.includes(m))
    );
    return matchingCode;
}

export default OMSError;
