import {createSlice} from '@reduxjs/toolkit'
import storage from '~/services/storage';
import initializeState from "~/store/redux/store/initial-state";
import initializeStateAction from "~/store/redux/store/init";
import {addToCart, modifyLineItem, modifyQuantity, removeFromCart, loadCart} from "./cartActions";

const cartSlice = createSlice({
    name: 'cart',
    initialState: initializeState.cart,
    reducers: {
        forgetCart: (state) => {
            state.isFetching = false
            state.error = null
            state.data = {}
        },
        finalizeCheckoutSuccess: (state) => {
            state.isFetching = false
            state.error = null
            state.data = {}
        },
        initializeStateFromStorage: (state) => {
            state.isFetching = false
            const cartId = storage.getItem('cart-id');
            if (cartId) {
                state.data.id = cartId;
            }
            state.data.lineItems = []
        },
        authLogoutRequest: (state) => {
            state.data = {
                ...state.data,
                number: null,
                token: null,
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(modifyQuantity.rejected, (state, action) => {
                state.error = action.payload
                state.isFetching = false
            })
            .addCase(modifyQuantity.pending, (state, action) => {
                state.isFetching = true
                state.error = null
            })
            .addCase(modifyQuantity.fulfilled, (state, action) => {
                state.isFetching = false
                state.error = null
                state.data = action.payload.data
            })
            .addCase(modifyLineItem.rejected, (state, action) => {
                state.error = action.payload
                state.isFetching = false
            })
            .addCase(modifyLineItem.pending, (state, action) => {
                state.isFetching = true
                state.error = null
            })
            .addCase(modifyLineItem.fulfilled, (state, action) => {
                state.isFetching = false
                state.error = null
                state.data = action.payload.data
            })
            .addCase(removeFromCart.rejected, (state, action) => {
                state.error = action.payload
                state.isFetching = false
            })
            .addCase(removeFromCart.pending, (state, action) => {
                state.isFetching = true
                state.error = null
            })
            .addCase(removeFromCart.fulfilled, (state, action) => {
                state.isFetching = false
                state.error = null
                state.data = action.payload.data
            })
            .addCase(addToCart.rejected, (state, action) => {
                state.error = action.payload
                state.isFetching = false
            })
            .addCase(addToCart.pending, (state, action) => {
                state.error = null
                state.isFetching = true
            })
            .addCase(addToCart.fulfilled, (state, action) => {
                state.error = null
                state.isFetching = false
                state.data = action.payload.data
            })
            .addCase(loadCart.rejected, (state, action) => {
                state.error = action.payload
                state.isFetching = false
            })
            .addCase(loadCart.pending, (state, action) => {
                state.error = null
                state.isFetching = true
            })
            .addCase(loadCart.fulfilled, (state, action) => {
                state.error = null
                state.isFetching = false
                state.data = action.payload
            })
            .addMatcher(
                (action) => action.type.endsWith('/rejected') && action.type.includes('GiftCard'),
                (state, action) => {
                    state.giftCard = {
                        isFetching: false,
                    }
                }
            )
            .addMatcher(
                (action) => action.type.endsWith('/fulfilled') && action.type.includes('GiftCard'),
                (state, action) => {
                    state.data = action.payload
                    state.giftCard = {
                        isFetching: false,
                    }
                }
            )
            .addMatcher(
                (action) => action.type.endsWith('/pending') && action.type.includes('GiftCard'),
                (state, action) => {
                    state.giftCard = {
                        isFetching: true,
                    }
                }
            )
            .addMatcher(
                (action) => action.type.endsWith('/pending') && action.type.includes('PromoCode'),
                (state, action) => {
                    state.promoCode = {
                        isFetching: true,
                    }
                }
            )
            .addMatcher(
                (action) => action.type.endsWith('/fulfilled') && action.type.includes('PromoCode'),
                (state, action) => {
                    state.promoCode = {
                        isFetching: false,
                    }
                    state.data = action.payload
                }
            )
            .addMatcher(
                (action) => action.type.endsWith('/rejected') && action.type.includes('PromoCode'),
                (state, action) => {
                    state.promoCode = {
                        isFetching: true,
                    }
                }
            )
            // .addMatcher(
            //     (action) => action.type.endsWith('/fulfilled') && action.type.includes('Cart'),
            //     (state, action) => {
            //         state.isFetching = false
            //         state.error = null
            //         state.data = action.payload.data
            //     }
            // )

    }
});

export const {authLogoutRequest, initializeStateFromStorage, forgetCart, finalizeCheckoutSuccess} = cartSlice.actions
export default cartSlice.reducer;
