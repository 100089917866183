import {
    AuthStatus,
    UserStatus,
} from '~/store/redux/store/types'

const InitialState= {
    auth: {
        status: AuthStatus.Initial,
        token: null,
    },
    cart: {
        data: {},
        isFetching: false,
        error: null,
        promoCode: {
            isFetching: false,
        },
        giftCard: {
            isFetching: false,
        },
    },
    catalog: {
        collections: [],
    },
    user: {
        data: {},
        status: UserStatus.Initial,
    },
}
export default InitialState
