import {
    useCatalogQuery
} from '~/hooks/graphql/queries/use-catalog'

export const usePreloadedState = () => {
    const {
        catalog = []
    } = useCatalogQuery()
    return {
        catalog
    }
}
