import { createChordClient } from '../client';
import { storage } from './storage';

export const createShopifyAuthClient = function createShopifyAuthClient(config) {
    const chord = createChordClient(config);
    const key = `${config.domain}-shopify-token`;

    const client = {
        getToken: async function() {

            const token = storage.getItem(key);
            if (!token) {
                throw new Error('User is not logged in.');
            }

            const { accessToken, expiresAt } = await chord.renewUserToken(token);
            storage.setItem(key, accessToken);
            return { accessToken, expiresAt };
        },

        isLoggedIn: function() {
            return !!storage.getItem(key);
        },

        login: async function({ email, password }) {
            const { accessToken, expiresAt } = await chord.createUserToken({ email, password });
            storage.setItem(key, accessToken);
            return { accessToken, expiresAt };
        },

        logout: async function() {
            const token = storage.getItem(key);
            storage.removeItem(key);
            return chord.deleteUserToken(token);
        },

        recover: async function(email) {
            return chord.recoverUser(email);
        },

        register: async function({ email, password }) {
            await chord.createUser({ email, password });
            return client.login({ email, password });
        },

        resetPassword: async function({ customerId, password, resetToken }) {
            const { accessToken, expiresAt } = await chord.resetUserPassword({ customerId, password, resetToken });
            storage.setItem(key, accessToken);
            return { accessToken, expiresAt };
        }
    };

    return client;
};
