import {
    graphql,
    useStaticQuery
} from 'gatsby'

export const useCatalogQuery = () => {
    const data = useStaticQuery(graphql`
    query CatalogQuery {
      catalog: contentfulCatalog(slug: { eq: "catalog" }) {
        collections {
          ...StoreCatalogCollectionFragment
        }
      }
    }
  `)

    return data
}
