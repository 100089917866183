import {createSlice, isFulfilled} from '@reduxjs/toolkit'
import initializeState from "~/store/redux/store/initial-state";
import {AuthStatus} from "../types";
import {logout} from "./authActions"

const authSlice = createSlice({
    name: 'auth', initialState: initializeState.auth, reducers: {
        clearSession: (state) => {
            state.token = null;
            state.status = AuthStatus.Idle;
        }, identifyRequest: (state, action) => {
            state.email = action.payload?.email || null;
        }, identifySuccess: (state, action) => {
            state.token = action.payload;
        }, identifyError: (state, action) => {
            state.error = true;
            state.error = action.payload.error
            state.meta = action.payload.meta || {};
        }
    }, extraReducers: (builder) => {
        builder
            .addCase(logout.fulfilled, (state, action) => {
                state.status = AuthStatus.Idle
                state.token = null
            })
            .addMatcher(// matcher can be defined inline as a type predicate function
                (action) => action.type.endsWith('/rejected') && action.type.includes('auth/'), (state, action) => {
                    state.status = AuthStatus.Failed
                    state.token = null
                })
            .addMatcher(// matcher can be defined inline as a type predicate function
                (action) => action.type.endsWith('/pending') && action.type.includes('auth/'), (state, action) => {
                    state.status = AuthStatus.Loading
                })
            // matcher can just return boolean and the matcher can receive a generic argument
            .addMatcher((action) => isFulfilled(action) && action.type !== logout.fulfilled && action.type.includes('auth/'), (state, action) => {
                state.status = AuthStatus.Succeeded
                state.token = action.payload
            })
    }
});

export const {clearSession, identifyRequest, identifyError, identifySuccess} = authSlice.actions
export default authSlice.reducer;
