import {createOMSClient} from './oms';
import {createAnalyticsClient} from './analytics';
import {formatVariantId} from './oms/utils';

export const createChordClient = (config) => {
    const oms = createOMSClient(config);
    const analytics = createAnalyticsClient(config, oms);

    return {
        analytics,
        addPromoCode: async (identifier, promoCode) => {
            try {
                analytics.trackCouponEntered({promoCode});
                const updatedOrder = await oms.addPromoCode(identifier, promoCode);
                analytics.trackCouponApplied({promoCode});
                return updatedOrder;
            } catch (error) {
                analytics.trackCouponDenied({promoCode, reason: error.message});
                throw error;
            }
        },
        addOrderItem: async (identifier, {quantity, variantId, user, placement, position, customAttributes}) => {
            const updatedOrder = await oms.addOrderItem(identifier, {quantity, variantId, customAttributes});
            const lineItem = updatedOrder.lineItems.find(item => item.variant.id === formatVariantId(variantId));
            analytics.trackProductAdded({
                lineItemId: lineItem.id,
                order: updatedOrder,
                user,
                quantity,
                placement,
                position
            });
            return updatedOrder;
        },
        applyGiftCard: async (identifier, giftCardCode) => {
            const last4Characters = giftCardCode.slice(-4);
            try {
                analytics.trackGiftCardEntered({lastCharacters: last4Characters});
                const order = await oms.applyGiftCard(identifier, giftCardCode);
                const giftCard = order.appliedGiftCards.find(gc => gc.lastCharacters.toLowerCase() === last4Characters.toLowerCase());
                analytics.trackGiftCardApplied({giftCard});
                return order;
            } catch (error) {
                analytics.trackGiftCardDenied({reason: error.message, lastCharacters: last4Characters});
                throw error;
            }
        },
        associateOrderWithUser: (identifier, token) => oms.associateOrderWithUser(identifier, token),
        checkout: async (identifier, customerAccessToken, user) => {
            const order = await oms.getOrder(identifier);
            analytics.trackCheckoutStarted({order, user});
            return oms.checkout(identifier, customerAccessToken);
        },
        createOrder: () => oms.createOrder(),
        createUser: async (credentials) => {
            const user = await oms.createUser(credentials);
            analytics.identify({user});
            return user;
        },
        getUser: async (token) => {
            const user = await oms.getUser(token);
            analytics.identify({user});
            return user;
        },
        createUserToken: (credentials) => oms.createUserToken(credentials),
        deleteUserToken: (token) => oms.deleteUserToken(token),
        createUserAddress: async (token, address, isDefaultAddress) => {
            const newAddress = await oms.createUserAddress(token, address);
            if (isDefaultAddress) {
                await oms.updateUserDefaultAddress(token, newAddress.id);
            }
            const user = await oms.getUser(token);
            analytics.identify({user});
            analytics.trackUserAddressAdded({address: newAddress, isDefault: isDefaultAddress, user});
            return user;
        },
        updateUserAddress: async (token, addressId, address, isDefaultAddress) => {
            const updatedAddress = await oms.updateUserAddress(token, addressId, address);
            if (isDefaultAddress) {
                await oms.updateUserDefaultAddress(token, updatedAddress.id);
            }
            const user = await oms.getUser(token);
            analytics.identify({user});
            analytics.trackUserAddressUpdated({address: updatedAddress, isDefault: isDefaultAddress, user});
            return user;
        },
        updateUserDefaultAddress: async (token, addressId) => {
            const user = await oms.updateUserDefaultAddress(token, addressId);
            const updatedAddress = user.defaultAddress;
            analytics.identify({user});
            analytics.trackUserAddressUpdated({address: updatedAddress, isDefault: true, user});
            return user;
        },
        removeUserAddress: async (token, addressId) => {
            await oms.removeUserAddress(token, addressId);
            const user = await oms.getUser(token);
            analytics.identify({user});
            analytics.trackUserAddressRemoved({addressId, user});
            return user;
        },
        getOrder: (identifier) => oms.getOrder(identifier),
        recoverUser: (email) => oms.recoverUser(email),
        removeGiftCard: async (identifier, appliedGiftCard) => {
            const order = await oms.removeGiftCard(identifier, appliedGiftCard.id);
            analytics.trackGiftCardRemoved({giftCard: appliedGiftCard});
            return order;
        },
        removeOrderItem: async (identifier, {lineItemId, user}) => {
            const order = await oms.getOrder(identifier);
            analytics.trackProductRemoved({lineItemId, order, user, quantity: 0});
            return oms.removeOrderItem(identifier, lineItemId);
        },
        removePromoCode: async (identifier, promoCode) => {
            const updatedOrder = await oms.removePromoCode(identifier);
            analytics.trackCouponRemoved({promoCode});
            return updatedOrder;
        },
        renewUserToken: (token) => oms.renewUserToken(token),
        resetUserPassword: (props) => oms.resetUserPassword(props),
        updateOrder: (identifier, props) => oms.updateOrder(identifier, props),
        updateOrderItem: async (identifier, {lineItemId, quantity, user, customAttributes}) => {
            const updatedOrder = await oms.updateOrderItem(identifier, {lineItemId, quantity, customAttributes});
            analytics.trackProductUpdated({lineItemId, order: updatedOrder, quantity, user});
            return updatedOrder;
        },
        getVariantAvailability: async (variantId) => {
            const identifier = formatVariantId(variantId);
            return oms.getVariantAvailability(identifier);
        }
    };
};
