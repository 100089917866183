module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"pVzrQG2HhcxBfjBSiwwQ6B2JktKheiVp","devKey":"pVzrQG2HhcxBfjBSiwwQ6B2JktKheiVp","trackPage":true,"trackPageImmediately":true,"trackPageOnlyIfReady":false,"trackPageOnRouteUpdate":true,"trackPageOnRouteUpdateDelay":50,"trackPageWithTitle":false,"delayLoad":false,"delayLoadDelay":1000,"delayLoadUntilActivity":false,"delayLoadUntilActivityAdditionalDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/opt/buildhome/repo/src/locales","languages":["en","fr"],"defaultLanguage":"fr","redirect":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
