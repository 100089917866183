import * as Sentry from '@sentry/browser'

/**
 * From the Netlify docs: the CONTEXT environment variable is the “name of the build’s deploy
 * context. It can be 'production', 'deploy-preview', or 'branch-deploy'.”
 * In development, it’ll fall back to NODE_ENV which Gatsby sets as development.
 */
const isProduction = () =>
    process.env.NODE_ENV === 'production' && process.env.CONTEXT === 'production'

export const logSentryError = (exception, extra) => {
    if (!isProduction()) {
        console.error('logSentryError:', exception, extra)
    }

    Sentry.withScope((scope) => {
        if (extra && typeof extra === 'object') {
            Object.keys(extra).forEach((key) => {
                scope.setExtra(key, extra[key])
            })
        }
        Sentry.captureException(exception)
    })
}