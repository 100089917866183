import {
    createShopifyAuthClient
} from '~/services/shopify/auth'

const domain = `${process.env.GATSBY_SHOPIFY_SUBDOMAIN}.myshopify.com`
const storefrontAccessToken = process.env.GATSBY_SHOPIFY_STOREFRONT_ACCESS_TOKEN
const multipassUrl = process.env.GATSBY_SHOPIFY_MULTIPASS_URL
const tenant = process.env.GATSBY_CHORD_TENANT_ID
const storeID = process.env.GATSBY_CHORD_STORE_ID
const storeName = process.env.GATSBY_SHOPIFY_SUBDOMAIN
const checkoutDomain = process.env.GATSBY_CHECKOUT_DOMAIN

const client = createShopifyAuthClient({
    domain,
    multipassUrl,
    storefrontAccessToken,
    tenant,
    storeID,
    storeName,
    checkoutDomain,
})
export default client
