if (!Object.entries) {
    /* eslint-disable-next-line func-names */
    Object.entries = function (obj) {
        const ownProps = Object.keys(obj)
        let i = ownProps.length
        const resArray = new Array(i) // preallocate the Array
        while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]]

        return resArray
    }
}
