const getOrderPromotions = (order) => {
    if (!order.discountApplications || order.discountApplications.length === 0) {
        return []
    }

    const {
        discountApplications,
        lineItemsSubtotalPrice,
        subtotalPrice
    } = order

    return discountApplications.map((discountApplication) => {
        const {
            code
        } = discountApplication

        const discountValue = +lineItemsSubtotalPrice.amount - +subtotalPrice

        return {
            code,
            id: code,
            label: code,
            amount: discountValue,
            displayAmount: `-$${discountValue.toFixed(2)}`,
        }
    })
}

export const getAllCartPromotionsForDisplay = (order) =>
    order ? ggetOrderPromotions(order) : []
