/* eslint-disable */
import {
    CheckoutFragment,
    CheckoutResponseFragment,
    CustomerFragment,
    CustomerAccessTokenFragment,
    CustomerUserErrorFragment,
    MailingAddressFragment,
    UserErrorFragment,
    ProductFragment,
    VariantFragment,
} from "./fragments";


export default {
    checkoutCustomerAssociateV2: `
        mutation checkoutCustomerAssociateV2($checkoutId: ID!, $customerAccessToken: String!) {
            checkoutCustomerAssociateV2(checkoutId: $checkoutId, customerAccessToken: $customerAccessToken) {
                ${CheckoutResponseFragment}
                customer {
                    ${CustomerFragment}
                }
            }
        }
    `,
    checkoutCreate: `
        mutation checkoutCreate($input: CheckoutCreateInput!) {
            checkoutCreate(input: $input) {
                ${CheckoutResponseFragment}
            }
        }
    `,
    customerCreate: `
        mutation customerCreate($input: CustomerCreateInput!) {
            customerCreate(input: $input) {
                customer {
                    ${CustomerFragment}
                }
                customerUserErrors {
                    ${CustomerUserErrorFragment}
                }
            }
        }
    `,
    customerAccessTokenCreate: `
        mutation customerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {
            customerAccessTokenCreate(input: $input) {
                customerAccessToken {
                    ${CustomerAccessTokenFragment}
                }
                customerUserErrors {
                    ${CustomerUserErrorFragment}
                }
            }
        }
    `,
    customerAccessTokenDelete: `
        mutation customerAccessTokenDelete($customerAccessToken: String!) {
            customerAccessTokenDelete(customerAccessToken: $customerAccessToken) {
                deletedAccessToken
                deletedCustomerAccessTokenId
                userErrors {
                    ${UserErrorFragment}
                }
            }
        }
    `,
    customerAccessTokenRenew: `
        mutation customerAccessTokenRenew($customerAccessToken: String!) {
            customerAccessTokenRenew(customerAccessToken: $customerAccessToken) {
                customerAccessToken {
                    ${CustomerAccessTokenFragment}
                }
                userErrors {
                    ${UserErrorFragment}
                }
            }
        }
    `,
    customerAddressCreate: `
        mutation customerAddressCreate($customerAccessToken: String!, $address: MailingAddressInput!) {
            customerAddressCreate(customerAccessToken: $customerAccessToken, address: $address) {
                customerAddress {
                    ${MailingAddressFragment}
                }
                customerUserErrors {
                    ${CustomerUserErrorFragment}
                }
            }
        }
    `,
    customerAddressDelete: `
        mutation customerAddressDelete($id: ID!, $customerAccessToken: String!) {
            customerAddressDelete(id: $id, customerAccessToken: $customerAccessToken) {
                customerUserErrors {
                    ${CustomerUserErrorFragment}
                }
            }
        }
    `,
    customerAddressUpdate: `
        mutation customerAddressUpdate($customerAccessToken: String!, $id: ID!, $address: MailingAddressInput!) {
            customerAddressUpdate(customerAccessToken: $customerAccessToken, id: $id, address: $address) {
                customerAddress {
                    ${MailingAddressFragment}
                }
                customerUserErrors {
                    ${CustomerUserErrorFragment}
                }
            }
        }
    `,
    customerDefaultAddressUpdate: `
        mutation customerDefaultAddressUpdate($customerAccessToken: String!, $addressId: ID!) {
            customerDefaultAddressUpdate(customerAccessToken: $customerAccessToken, addressId: $addressId) {
                customer {
                    ${CustomerFragment}
                }
                customerUserErrors {
                    ${CustomerUserErrorFragment}
                }
            }
        }
    `,
    customerRecover: `
        mutation customerRecover($email: String!) {
            customerRecover(email: $email) {
                customerUserErrors {
                    ${CustomerUserErrorFragment}
                }
            }
        }
    `,
    customerQuery: `
        query ($customerAccessToken: String!) {
            customer(customerAccessToken: $customerAccessToken) {
                ${CustomerFragment}
            }
        }
    `,
    customerReset: `
        mutation customerReset($id: ID!, $input: CustomerResetInput!) {
            customerReset(id: $id, input: $input) {
                customerAccessToken {
                    ${CustomerAccessTokenFragment}
                }
                customerUserErrors {
                    ${CustomerUserErrorFragment}
                }
            }
        }
    `,
    getCheckout: `
        query ($id: ID!) {
            node(id: $id) {
                id
                ... on Checkout {
                    ${CheckoutFragment}
                }
            }
        }
    `,
    getProductByHandle: `
        query ($handle: String!) {
            productByHandle(handle: $handle) {
                ${ProductFragment}
            }
        }
    `,
    getVariantById: `
        query ($id: ID!) {
            variant: node(id: $id) {
                ... on ProductVariant {
                    ${VariantFragment}
                }
            }
        }
    `,
    getVariantAvailability: `
        query ($id: ID!) {
            variant: node(id: $id) {
                ... on ProductVariant {
                    sku
                    availableForSale
                    currentlyNotInStock
                }
            }
        }
    `,
    checkoutAttributesUpdateV2: `
        mutation checkoutAttributesUpdateV2($checkoutId: ID!, $input: CheckoutAttributesUpdateV2Input!) {
            checkoutAttributesUpdateV2(checkoutId: $checkoutId, input: $input) {
                ${CheckoutResponseFragment}
            }
        }
    `,
    checkoutLineItemsAdd: `
        mutation checkoutLineItemsAdd($lineItems: [CheckoutLineItemInput!]!, $checkoutId: ID!) {
            checkoutLineItemsAdd(lineItems: $lineItems, checkoutId: $checkoutId) {
                ${CheckoutResponseFragment}
            }
        }
    `,
    checkoutLineItemsRemove: `
        mutation checkoutLineItemsRemove($checkoutId: ID!, $lineItemIds: [ID!]!) {
            checkoutLineItemsRemove(checkoutId: $checkoutId, lineItemIds: $lineItemIds) {
                ${CheckoutResponseFragment}
            }
        }
    `,
    checkoutLineItemsUpdate: `
        mutation checkoutLineItemsUpdate($checkoutId: ID!, $lineItems: [CheckoutLineItemUpdateInput!]!) {
            checkoutLineItemsUpdate(checkoutId: $checkoutId, lineItems: $lineItems) {
                ${CheckoutResponseFragment}
            }
        }
    `,
    checkoutDiscountCodeApplyV2: `
        mutation checkoutDiscountCodeApplyV2($discountCode: String!, $checkoutId: ID!) {
            checkoutDiscountCodeApplyV2(discountCode: $discountCode, checkoutId: $checkoutId) {
                ${CheckoutResponseFragment}
            }
        }
    `,
    checkoutGiftCardsAppend: `
        mutation checkoutGiftCardsAppend($giftCardCodes: [String!]!, $checkoutId: ID!) {
            checkoutGiftCardsAppend(giftCardCodes: $giftCardCodes, checkoutId: $checkoutId) {
                ${CheckoutResponseFragment}
            }
        }
    `,
    checkoutGiftCardRemoveV2: `
        mutation checkoutGiftCardRemoveV2($appliedGiftCardId: ID!, $checkoutId: ID!) {
            checkoutGiftCardRemoveV2(appliedGiftCardId: $appliedGiftCardId, checkoutId: $checkoutId) {
                ${CheckoutResponseFragment}
            }
        }
    `,
    checkoutDiscountCodeRemove: `
        mutation checkoutDiscountCodeRemove($checkoutId: ID!) {
            checkoutDiscountCodeRemove(checkoutId: $checkoutId) {
                ${CheckoutResponseFragment}
            }
        }
    `,
}
