import {
    createSelector
} from 'reselect'

const getCatalogCollections = (state) => state.catalog.collections

export const getAllProducts = createSelector(
    [getCatalogCollections],
    (collections) => {
        const products = collections.reduce(
            (acc, collection = {}) =>
                collection.products ? acc.concat(collection.products) : acc, []
        )

        return products
    }
)

