import { storage as memory } from './memory';

function supportsLocalStorage() {
    const key = 'chord-js-auth-shopify-test-key';
    try {
        window.localStorage.setItem(key, 'test');
        window.localStorage.removeItem(key);
        return true;
    } catch (error) {
        return false;
    }
}

function getStorage() {
    return supportsLocalStorage() ? window.localStorage : memory;
}

export const storage = getStorage();
