import {createAction, createAsyncThunk} from '@reduxjs/toolkit';
import { logSentryError } from '~/services/sentry';
import { loadCart } from '../cart';
import { getToken } from '../auth';
import { loadUser } from '../user';
import { initializeStateFromStorage } from '../cart/cartSlice';



const initializeState = createAsyncThunk(
    'initializeState',
    async (_, { dispatch }) => {
        try {
            dispatch(initializeStateFromStorage());
        } catch (error) {
            logSentryError(error, {
                source: 'initializeState.initializeStateFromStorage',
            });
        }

        try {
            dispatch(loadCart());
        } catch (error) {
            logSentryError(error, {
                source: 'initializeState.loadCart',
            });
        }

        // try {
        //     const token = await dispatch(getToken()).unwrap();
        //     if (token) {
        //         await dispatch(loadUser());
        //     }
        // } catch (error) {
        //     if (error.message !== 'User is not logged in.') {
        //         logSentryError(error, {
        //             source: 'initializeState.getToken',
        //         });
        //     }
        // }
    }
);

export default initializeState
