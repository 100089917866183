/** @jsx jsx */
import {Fragment} from 'react'
import PropTypes from 'prop-types'
import {Link} from 'gatsby'
import {jsx} from 'theme-ui'
import {ConsentManager} from '@segment/consent-manager'
import inEU from '@segment/in-eu'

const bannerActionsBlock = ({acceptAll, denyAll}) => (
    <div>
        <button
            type="button"
            onClick={acceptAll}
            sx={{
                backgroundColor: 'white',
                color: 'black',
                mr: '5px',
                padding: '5px 10px',
                border: 'none',
                borderRadius: '2px',
                cursor: 'pointer'
            }}
        >
            Allow all
        </button>
        <button
            type="button"
            onClick={denyAll}
            sx={{
                backgroundColor: 'white',
                color: 'black',
                padding: '5px 10px',
                border: 'none',
                borderRadius: '2px',
                cursor: 'pointer'
            }}
        >
            Deny all
        </button>
    </div>
)

const bannerContent = (
    <span>
    Nous utilisons des cookies (et d'autres technologies similaires) pour collecter des données afin d'améliorer votre expérience sur notre site.
    <br/>
    En utilisant notre site web, vous acceptez la collecte de données telle qu'elle est décrite
    dans notre <Link to="/politique-de-confidentitalite">Politique de Confidentialité</Link>.
  </span>
)

const preferencesDialogContent = (
    <div>
        <p>
            Nous utilisons les données collectées par les cookies et les bibliothèques JavaScript afin d'améliorer votre expérience de navigation, d'analyser le trafic du site et de vous proposer des services personnalisés.
            navigation, analyser le trafic du site, fournir des publicités personnalisées et améliorer les performances de notre site.
        </p>
        <p>
            En utilisant notre site web, vous acceptez nos conditions d'utilisation.{' '}
            <a href="/politique-de-confidentitalite" target="_blank" rel="noopener noreferrer">
                Politique de Confidentialité
            </a>
            .
        </p>
        <p>
            Le tableau ci-dessous indique comment nous utilisons ces données par catégorie. Pour ne pas participer à une catégorie de collecte de données, sélectionnez "Non" et enregistrez vos préférences.
        </p>
    </div>
)

const cancelDialogContent = (
    <div>
        Vos préférences n'ont pas été enregistrées. En continuant à utiliser notre site web,
        vous acceptez notre{' '}
        <a href="/politique-de-confidentitalite" target="_blank" rel="noopener noreferrer">
            Politique de Confidentialité
        </a>
        .
    </div>
)

const getSegmentWriteKey = () => {
    return process.env.NODE_ENV === 'production' &&
    process.env.CONTEXT === 'production'
        ? process.env.GATSBY_SEGMENT_PROD_WRITE_KEY
        : process.env.GATSBY_SEGMENT_DEV_WRITE_KEY
}

const onError = error => {
    console.log('ConsentManagerBanner error', error)
}

const shouldRequireConsent = () => {
    if (process.env.GATSBY_SEGMENT_SHOULD_REQUIRE_CONSENT === 'true') {
        return true
    }

    return inEU()
}

/**
 * This file integrates Segment's consent-manager to protect our visitors privacy.
 *
 * See https://github.com/segmentio/consent-manager
 */
const ConsentManagerWrapper = ({children}) => {
    const writeKey = getSegmentWriteKey()

    return (
        <Fragment>
            {children}

            <div
                sx={{
                    position: 'sticky',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    zIndex: 999,
                    '& > div > div': {
                        pl: '8px',
                        pr: '40px'
                    },
                    '[title="Close"]': {
                        right: '8px',
                        left: 'auto'
                    }
                }}
            >
                <ConsentManager
                    writeKey={writeKey}
                    shouldRequireConsent={shouldRequireConsent}
                    implyConsentOnInteraction={true}
                    bannerContent={bannerContent}
                    bannerSubContent="Gérer vos préferences ici!"
                    bannerActionsBlock={bannerActionsBlock}
                    bannerHideCloseButton={false}
                    bannerAsModal={false}
                    bannerTextColor="#FFFFFF"
                    bannerBackgroundColor="#D47555"
                    preferencesDialogTitle="Préférence de collecte de données"
                    preferencesDialogContent={preferencesDialogContent}
                    cancelDialogTitle="Êtes vous sûr de vouloir annuler?"
                    cancelDialogContent={cancelDialogContent}
                    onError={onError}
                />
            </div>
        </Fragment>
    )
}

ConsentManagerWrapper.propTypes = {
    children: PropTypes.node.isRequired
}

export default ConsentManagerWrapper
