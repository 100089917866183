import {createAsyncThunk} from '@reduxjs/toolkit';

export const identify = createAsyncThunk('analytics/identify', async (traits, {getState, extra}) => {
    const {user} = getState();
    await extra.api.analytics.identify({
        traits, user: user.data
    });
});

export const trackCartViewed = createAsyncThunk('analytics/trackCartViewed', async (_, {getState, extra}) => {
    const {cart, user} = getState();
    await extra.api.analytics.trackCartViewed({
        order: cart.data, user: user.data
    });
});

export const trackCollectionClicked = createAsyncThunk(
    'analytics/trackCollectionClicked',
    async ({
               id,
               description,
               imageUrl,
               slug,
               title
           }, {extra}) => {
        await extra.api.analytics.trackCollectionClicked({
            id, description, imageUrl, slug, title,
        });
    });

export const trackEmailCaptured = createAsyncThunk(
    'analytics/trackEmailCaptured',
    async ({
               email, placement
           }, {extra}) => {
        await extra.api.analytics.trackEmailCaptured({
            email, placement
        });
    });

export const trackProductClicked = createAsyncThunk(
    'analytics/trackProductClicked',
    async ({
               productHandle,
               placement,
               position
           }, {extra, getState}) => {
        const {
            cart, user
        } = getState()
        await extra.api.analytics.trackProductClicked({
            productHandle, order: cart.data, user: user.data, placement, position,
        })
    });

export const trackProductDetailsClicked = createAsyncThunk(
    'analytics/trackProductDetailsClicked',
    async ({
               title,
               productHandle
           }, {extra}) => {
        await extra.api.analytics.trackProductDetailsClicked({
            title, productHandle,
        })
    });

export const trackVariantClicked = createAsyncThunk(
    'analytics/trackVariantClicked',
    async ({
               variantId,
           }, {extra, getState}) => {
        const {
            cart, user
        } = getState()
        await extra.api.analytics.trackVariantClicked({
            variantId, order: cart.data, user: user.data,
        })
    });

export const trackProductListFiltered = createAsyncThunk(
    'analytics/trackProductListFiltered',
    async ({
               filter,
               list,
               sort
           }, {
               getState,
               extra
           }) => {
        const {
            cart, user
        } = getState()
        await extra.api.analytics.trackProductListFiltered({
            filter, list, sort, order: cart.data, user: user.data,
        })
    })

export const trackProductListViewed = createAsyncThunk('analytics/trackProductListViewed', async ({list}, {extra}) => {
    await extra.api.analytics.trackProductListViewed({
        list
    })
})

export const trackProductViewed = createAsyncThunk('analytics/trackProductViewed', async ({productHandle}, {
    getState, extra
}) => {
    const {
        cart, user
    } = getState()
    await extra.api.analytics.trackProductViewed({
        productHandle, order: cart.data, user: user.data,
    })
})

export const trackPromotionClicked = createAsyncThunk('analytics/trackPromotionClicked', async ({
                                                                                                    id,
                                                                                                    creative,
                                                                                                    name,
                                                                                                    position
                                                                                                }, {
                                                                                                    getState, extra
                                                                                                }) => {
    const {
        user
    } = getState()
    await extra.api.analytics.trackPromotionClicked({
        promotion: {
            creative, id, name, position,
        }, user: user.data,
    })
})

export const trackPromotionViewed = createAsyncThunk('analytics/trackPromotionViewed', async ({
                                                                                                  id,
                                                                                                  creative,
                                                                                                  name,
                                                                                                  position
                                                                                              }, {getState, extra}) => {
    const {
        user
    } = getState()
    await extra.api.analytics.trackPromotionViewed({
        promotion: {
            creative, id, name, position,
        }, user: user.data,
    })
})

export const trackStockRequestCreated = createAsyncThunk('analytics/trackStockRequestCreated', async ({
                                                                                                          email,
                                                                                                          productHandle
                                                                                                      }, {
                                                                                                          extra
                                                                                                      }) => {
    await extra.analytics.trackStockRequestCreated({
        email, productHandle
    })
})


export const trackUserPasswordResetCompleted = createAsyncThunk('analytics/trackUserPasswordResetCompleted', async (_, {
    getState,
    extra
}) => {
    const {
        user
    } = getState()
    await extra.api.analytics.trackUserPasswordResetCompleted({
        user: user.data
    })
})

export const trackUserPasswordResetStarted = createAsyncThunk('analytics/trackUserPasswordResetStarted', async (email, {extra}) => {
    await extra.api.analytics.trackUserPasswordResetStarted({
        email
    })
})

export const trackUserSignedIn = createAsyncThunk('analytics/trackUserSignedIn', async (_, {getState, extra}) => {
    const {
        user
    } = getState()
    await extra.api.analytics.trackUserSignedIn({
        user: user.data
    })
})

export const trackUserSignedOut = createAsyncThunk('analytics/trackUserSignedOut', async (_, {getState, extra}) => {
    const {
        user
    } = getState()
    await extra.api.analytics.trackUserSignedOut({
        user: user.data
    })
})

export const trackUserSignedUp = createAsyncThunk('analytics/trackUserSignedUp', async (_, {getState, extra}) => {
    const {
        user
    } = getState()
    await extra.api.analytics.trackUserSignedUp({
        signupType: 'organic', user: user.data,
    })
})

export const trackNavigationClicked = createAsyncThunk('analytics/trackNavigationClicked', async ({
                                                                                                      title,
                                                                                                      url,
                                                                                                      placement
                                                                                                  }, {extra}) => {
    await extra.api.analytics.trackNavigationClicked({
        title, url, placement,
    })
})
