import { createSlice } from '@reduxjs/toolkit'
import initializeState from "~/store/redux/store/initial-state";
import {UserStatus} from "../types";


const userSlice = createSlice({
    name: 'user',
    initialState: initializeState.user,
    reducers: {
        clearSession: (state) => {
            state.data = {};
            state.status = UserStatus.Idle;
        },
        authLogoutSuccess: (state) => {
            state.data = {};
            state.status = UserStatus.Idle;
        }
    },
    extraReducers:
    (builder) => {
        builder
            .addMatcher(
                // matcher can be defined inline as a type predicate function
                (action) => action.type.endsWith('/rejected') && action.type.includes('user/'),
                (state, action) => {
                    state.status = UserStatus.Failed
                    state.error = action.error
                }
            )
            .addMatcher(
                // matcher can be defined inline as a type predicate function
                (action) => action.type.endsWith('/pending') && action.type.includes('user/'),
                (state, action) => {
                    state.status = UserStatus.Loading
                }
            )
            // matcher can just return boolean and the matcher can receive a generic argument
            .addMatcher(
                (action) => action.type.endsWith('/fulfilled') && action.type.includes('user/'),
                (state, action) => {
                    state.status = UserStatus.Succeeded
                    state.data = action.payload
                }
            )
    }
})

export const { clearSession, authLogoutSuccess } = userSlice.actions
export default userSlice.reducer;
