export const FulfillmentTrackingInfoFragment = `
  number
  url
`;

export const FulfillmentFragment = `
  trackingCompany
  trackingInfo {
    ${FulfillmentTrackingInfoFragment}
  }
`;

export const MoneyV2Fragment = `
  amount
  currencyCode
`;

export const MailingAddressFragment = `
  address1
  address2
  city
  company
  country
  countryCodeV2
  firstName
  formatted
  formattedArea
  id
  lastName
  latitude
  longitude
  name
  phone
  province
  provinceCode
  zip
`;

export const ProductFragment = `
  id
  availableForSale
  description
  descriptionHtml
  handle
  images (first: 10) {
    edges {
      node {
        id
        originalSrc
      }
    }
  }
  options (first: 10) {
    id
    name
    values
  }
  productType
  publishedAt
  title
`;

export const VariantFragment = `
  id
  title
  priceV2 {
    ${MoneyV2Fragment}
  }
  image {
    id
    originalSrc
  }
  weight
  availableForSale
  sku
  compareAtPriceV2 {
    ${MoneyV2Fragment}
  }
  selectedOptions {
    name
    value
  }
  unitPriceMeasurement {
    measuredType
    quantityUnit
    quantityValue
    referenceUnit
    referenceValue
  }
  product {
    ${ProductFragment}
  }
`;

const DiscountApplicationFragment = `
  allocationMethod
  targetSelection
  targetType
  ... on DiscountCodeApplication {
    code
  }
  value {
    __typename
    ... on MoneyV2 {
      ${MoneyV2Fragment}
    }
    ... on PricingPercentageValue {
      percentage
    }
  }
`;

const LineItemFragment = `
  id
  title
  quantity
  variant {
    ${VariantFragment}
  }
  discountAllocations {
    allocatedAmount {
      ${MoneyV2Fragment}
    }
    discountApplication {
      ${DiscountApplicationFragment}
    }
  }
  customAttributes {
    key
    value
  }
`;

export const CheckoutFragment = `
  id
  lineItems (first: 10) {
    edges {
      node {
        ${LineItemFragment}
      }
    }
  }
  ready
  requiresShipping
  note
  paymentDueV2 {
    ${MoneyV2Fragment}
  }
  webUrl
  orderStatusUrl
  taxExempt
  taxesIncluded
  currencyCode
  totalTaxV2 {
    ${MoneyV2Fragment}
  }
  lineItemsSubtotalPrice {
    ${MoneyV2Fragment}
  }
  subtotalPriceV2 {
    ${MoneyV2Fragment}
  }
  totalPriceV2 {
    ${MoneyV2Fragment}
  }
  completedAt
  createdAt
  updatedAt
  email
  discountApplications (first: 10) {
    edges {
      node {
        ${DiscountApplicationFragment}
      }
    }
  }
  appliedGiftCards {
    id
    amountUsedV2 {
      ${MoneyV2Fragment}
    }
    balanceV2 {
      ${MoneyV2Fragment}
    }
    lastCharacters
  }
  shippingAddress {
    ${MailingAddressFragment}
  }
  shippingLine {
    handle
    title
    priceV2 {
      ${MoneyV2Fragment}
    }
  }
  customAttributes {
    key
    value
  }
  order {
    id
    name
  }
`;

export const CustomerUserErrorFragment = `
  code
  field
  message
`;

export const CheckoutUserErrorFragment = `
  code
  field
  message
`;

export const UserErrorFragment = `
  field
  message
`;

export const CheckoutResponseFragment = `
  checkout {
    ${CheckoutFragment}
  }
  checkoutUserErrors {
    ${CheckoutUserErrorFragment}
  }
`;

export const CustomerAccessTokenFragment = `
  accessToken
  expiresAt
`;

export const OrderFragment = `
  cancelReason
  canceledAt
  currencyCode
  currentSubtotalPrice {
    ${MoneyV2Fragment}
  }
  currentTotalPrice {
    ${MoneyV2Fragment}
  }
  currentTotalTax {
    ${MoneyV2Fragment}
  }
  customerLocale
  customerUrl
  edited
  email
  financialStatus
  fulfillmentStatus
  id
  name
  orderNumber
  originalTotalPrice {
    ${MoneyV2Fragment}
  }
  phone
  processedAt
  shippingAddress {
    ${MailingAddressFragment}
  }
  shippingDiscountAllocations {
    allocatedAmount {
      ${MoneyV2Fragment}
    }
    discountApplication {
      ${DiscountApplicationFragment}
    }
  }
  statusUrl
  subtotalPriceV2 {
    ${MoneyV2Fragment}
  }
  successfulFulfillments {
    ${FulfillmentFragment}
  }
  totalPriceV2 {
    ${MoneyV2Fragment}
  }
  totalRefundedV2 {
    ${MoneyV2Fragment}
  }
  totalShippingPriceV2 {
    ${MoneyV2Fragment}
  }
  totalTaxV2 {
    ${MoneyV2Fragment}
  }
`;

export const CustomerFragment = `
  addresses(first: 10) {
    edges {
      node {
        ${MailingAddressFragment}
      }
    }
  }
  createdAt
  defaultAddress {
    ${MailingAddressFragment}
  }
  displayName
  email
  firstName
  id
  lastName
  orders(first: 10) {
    edges {
      node {
        ${OrderFragment}
      }
    }
  }
  phone
`;
