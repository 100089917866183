import {
    logSentryError
} from '~/services/sentry'

/* eslint-disable-next-line no-unused-vars */
export const logger = (store) => (next) => (action) => {
    const result = next(action)

    const {
        error,
        meta
    } = action
    const ignore = meta && meta.disableLogging === true
    const extra = meta ? {
        source: action.type,
        meta
    } : {
        source: action.type
    }

    if (error && !ignore) {
        logSentryError(action.payload, extra)
    }

    return result
}
