import axios from 'axios';

const createRequest = (config) =>  {
  return axios.create({
    baseURL: "https://".concat(config.checkoutDomain ? config.checkoutDomain : config.domain, "/api/2022-07/graphql.json"),
    headers: {
      'Content-Type': 'application/json',
      'X-Shopify-Storefront-Access-Token': config.storefrontAccessToken
    }
  });
};
export default createRequest;
