let data = {};

export const storage = {
    getItem: function (key) {
        return data[key] || null;
    },
    setItem: function (key, value) {
        data[key] = String(value);
    },
    removeItem: function (key) {
        delete data[key];
    }
};
