import {Theme} from 'theme-ui'

const colorsold = {
    black: '#233437',
    navy: '#1F3438',
    perracotta: '#D47555',
    sage: '#6B8060',
    cream: '#F7E6C1',
    white: '#FCFCFA',
    gray: '#C7C7C7',
    secondaryGray: '#535353',
    tertiaryGray: '#E2E1D9',
    lightBlue: '#aec9d3',
    pink: '#FABBCB',
    turquoise: '#58D8D0',
    skyBlue: '#a7cad4',
    plum: '#6A2828',
    iconicsBlack: '#262020',
    marigold: '#F0B323',
    midnight: '#051C2C',
    emerald: '#183029',
    stone: '#C1B2A2',
    siltGreen: '#A7BDB1',
    brickRed: '#C12F28',
    red: '#CC1223',
    background: '#fcfcf9',
    text: '#233437',
    coolGray: '#C9C6B7',
    slate: '#58728B',
    darkenPeracotta: '#BC522F',
    darkenSage: '#5B6D51',
    mist: '#B5C9BF'
} as const

const colors = {
    black: '#233437',
    bistre: '#38221F',
    perracotta: '#D47555',
        sage: '#7D3030',
    cream: '#F7E6C1',
    white: '#FCFCFA',
    gray: '#C7C7C7',
    secondaryGray: '#535353',
    tertiaryGray: '#E2E1D9',
    lightBlue: '#aec9d3',
    pink: '#FABBCB',
    turquoise: '#58D8D0',
    skyBlue: '#a7cad4',
    plum: '#6A2828',
    iconicsBlack: '#202620',
    marigold: '#F0B323',
    midnight: '#051C2C',
    emerald: '#183029',
    stone: '#C1B2A2',
    siltGreen: '#A7BDB1',
    brickRed: '#C12F28',
    red: '#CC1223',
    background: '#fcfcf9',
    text: '#382F24',
    coolGray: '#C9C6B7',
    slate: '#58728B',
    darkenPeracotta: '#BC522F',
    darkenSage: '#6A2828',
    mist: '#B5C9BF'
} as const

const defaultButton = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'fit-content',
    cursor: 'pointer',
    fontSize: 16,
    lineHeight: '28px',
    whiteSpace: 'nowrap',
    padding: '10px 24px',
    transition: '0.2s ease-in-out all',
    textTransform: 'capitalize',
    fontWeight: '400',
    textDecoration: 'none',
    border: 'none',
    borderRadius: 25
} as const

const fonts = {
    body: '"Moderat", system-ui, sans-serif',
    heading: '"Moderat", system-ui, sans-serif',
    primary: '"Moderat", system-ui, sans-serif',
    secondary: '"Suisse Works", system-ui, sans-serif'
} as const

const theme: Theme = {
    space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
    breakpoints: ['768px', '992px', '1200px', '1440px'],
    fonts,
    fontSizes: [12, 14, 16, 18, 20, 24, 32, 48, 64, 96],
    fontWeights: {
        regular: 400,
        body: 400,
        heading: 700,
        bold: 700
    },
    lineHeights: {
        body: 1.5,
        heading: 1.125
    },
    colors: colors,
    styles: {
        root: {
            '*': {
                margin: 0,
                padding: 0,
                WebkitFontSmoothing: 'antialiased',
                textRendering: 'optimizeLegibility'
            },
            variant: 'text.body',
            a: {color: 'inherit', textDecoration: 'none'},
            button: {fontFamily: 'body'},
            '.rmz-chat-bubble': {
                zIndex: '1 !important'
            }
        }
    },
    buttons: {
        primary: {
            ...defaultButton,
            backgroundColor: colors.cream,
            color: colors.bistre,
            minWidth: '250px',
            '&:hover': {
                backgroundColor: colors.sage,
                color: colors.bistre
            }
        },
        primarySmall: {
            ...defaultButton,
            backgroundColor: colors.cream,
            color: colors.bistre,
            fontSize: '14px',
            height: '40px'
        },
        secondary: {
            ...defaultButton,
            fontWeight: 'normal',
            minWidth: '250px',
            backgroundColor: colors.bistre,
            color: 'white',
            '&:hover': {
                backgroundColor: colors.cream,
                color: colors.bistre
            }
        },
        secondarySmall: {
            ...defaultButton,
            backgroundColor: colors.bistre,
            color: 'white',
            '&:hover': {
                backgroundColor: colors.cream,
                color: colors.bistre
            },
            textTransform: 'none',
            fontWeight: '400',
            border: '1px solid black',
            width: 'fit-content',
            letterSpacing: '0.5px',
            height: '34px',
            fontSize: '14px'
        },
        neutral: {
            ...defaultButton,
            fontWeight: 'bold',
            minWidth: '250px',
            backgroundColor: colors.white,
            color: colors.black,
            '&:hover': {
                backgroundColor: colors.black,
                color: colors.white
            }
        },
        tertiary: {
            ...defaultButton,
            backgroundColor: colors.perracotta,
            color: 'white',
            '&:hover': {
                backgroundColor: '#bf694d'
            }
        },
        tertiaryNav: {
            ...defaultButton,
            textTransform: undefined,
            fontWeight: undefined,
            height: 40,
            backgroundColor: colors.perracotta,
            color: 'white',
            '&:hover': {
                backgroundColor: '#bf694d'
            }
        },
        secondaryLarge: {
            ...defaultButton,
            backgroundColor: colors.black,
            color: 'white',
            fontSize: ['13px', null, '16px'],
            width: '250px',
            height: '48px',
            '&:hover': {
                backgroundColor: colors.cream,
                color: colors.black
            }
        },
        checkout: {
            ...defaultButton,
            backgroundColor: colors.sage,
            color: 'white',
            fontSize: '16px',
            fontWeight: 'bold',
            letterSpacing: '0.05em',
            width: '100%',
            height: '48px',
            '&:hover': {
                backgroundColor: colors.cream,
                color: colors.black
            }
        },
        checkoutMarigold: {
            ...defaultButton,
            textTransform: 'uppercase',
            fontWeight: 'bold',
            letterSpacing: '0.05em',
            backgroundColor: colors.marigold,
            color: colors.bistre,
            width: '100%',
            '&:hover': {
                backgroundColor: colors.bistre,
                color: colors.white
            }
        },
        outline: {
            ...defaultButton,
            backgroundColor: 'white',
            color: colors.bistre,
            textTransform: 'none',
            fontWeight: '400',
            border: '1px solid black',
            width: 'fit-content',
            padding: '8px 45px',
            letterSpacing: '0.5px',
            fontSize: '16px',
            ':hover': {
                backgroundColor: colors.bistre,
                color: 'white'
            }
        },
        outlineSmall: {
            ...defaultButton,
            backgroundColor: 'white',
            color: colors.bistre,
            textTransform: 'none',
            fontWeight: '400',
            border: '1px solid black',
            width: 'fit-content',
            letterSpacing: '0.5px',
            height: '34px',
            fontSize: '14px',
            ':hover': {
                backgroundColor: colors.bistre,
                color: 'white'
            }
        },
        underlined: {
            ...defaultButton,
            maxWidth: 'fit-content',
            backgroundColor: 'transparent',
            color: 'inherit',
            textTransform: 'capitalize',
            letterSpacing: '0.5px',
            fontSize: 'inherit',
            border: 'transparent',
            textDecoration: 'underline'
        },
        transparent: {
            ...defaultButton,
            backgroundColor: 'transparent',
            color: colors.bistre,
            textTransform: 'capitalize',
            fontWeight: '400',
            border: '1px solid black',
            width: 'fit-content',
            padding: '8px 45px',
            letterSpacing: '0.5px',
            fontSize: '16px',
            ':hover': {
                backgroundColor: colors.bistre,
                color: 'white'
            }
        },
        transparenthover: {
            ...defaultButton,
            backgroundColor: colors.bistre,
            color: 'white',
            textTransform: 'capitalize',
            fontWeight: '400',
            width: 'fit-content',
            minWidth: '250px',
            padding: '8px 45px',
            letterSpacing: '0.5px',
            fontSize: '16px',
            border: `1px solid ${colors.bistre}`,
            ':hover': {
                backgroundColor: 'transparent',
                color: colors.bistre,
                border: `1px solid ${colors.bistre}`,
            }
        },
        transparentwhite: {
            ...defaultButton,
            backgroundColor: 'transparent',
            color: 'white',
            textTransform: 'capitalize',
            fontWeight: '400',
            width: 'fit-content',
            minWidth: '250px',
            padding: '8px 45px',
            letterSpacing: '0.5px',
            fontSize: '16px',
            border: `1px solid ${colors.white}`,
            ':hover': {
                backgroundColor: colors.cream,
                color: colors.bistre,
                border: `1px solid ${colors.cream}`,
            }
        },
        iconicsblack: {
            ...defaultButton,
            width: 'fit-content',
            backgroundColor: colors.iconicsBlack,
            color: colors.white,
            textTransform: 'capitalize',
            letterSpacing: '0.5px',
            fontSize: 'text.small',
            border: `1px solid ${colors.iconicsBlack}`,
            padding: '8px 45px',

            ':hover': {
                backgroundColor: 'transparent',
                color: colors.iconicsBlack,
                border: `1px solid ${colors.iconicsBlack}`
            }
        },
        midnight: {
            variant: 'buttons.iconicsblack',
            backgroundColor: colors.midnight,
            border: `1px solid ${colors.midnight}`,
            textTransform: 'capitalize',
            ':hover': {
                backgroundColor: colors.lightBlue,
                color: colors.bistre,
                border: `1px solid ${colors.lightBlue}`
            }
        },
        unstyled: {
            background: 'none',
            color: 'inherit',
            fontSize: 'inherit',
            padding: 0
        },
        marigold: {
            ...defaultButton,
            backgroundColor: colors.marigold,
            fontSize: ['13px', null, '16px'],
            textTransform: 'capitalize',
            fontWeight: 'bold',
            letterSpacing: '0.05em',
            color: colors.bistre,
            paddingLeft: '40px',
            paddingRight: '40px',
            maxWidth: 'fit-content',
            height: '48px',
            ':hover': {
                backgroundColor: colors.bistre,
                color: colors.white
            }
        },
        marigoldalt: {
            variant: 'buttons.marigold',
            ':hover': {
                backgroundColor: 'transparent',
                color: colors.marigold,
                outline: `2px solid ${colors.marigold}`
            }
        },
        creamtext: {
            ...defaultButton,
            color: colors.cream,
            ':hover': {
                textDecoration: 'underline'
            }
        }
    },
    cards: {
        primary: {
            padding: 2,
            borderRadius: 4,
            boxShadow: '0 0 8px rgba(0, 0, 0, 0.125)'
        },
        compact: {
            padding: 1,
            borderRadius: 2,
            border: '1px solid',
            borderColor: 'muted'
        }
    },
    layout: {
        container: {
            margin: 'auto',
            maxWidth: '1440px',
            padding: ['0 20px', null, '0 45px'],
            overflow: 'hidden',
            width: '100%'
        },
        small: {
            maxWidth: '1200px',
            padding: ['0 20px', null, '0 45px']
        },
        xsmall: {
            maxWidth: '1024px',
            padding: ['0 20px', null, '0 45px']
        },
        large: {
            maxWidth: '1600px',
            padding: ['0 20px', null, '0 45px']
        },
        tablet: {
            margin: 'auto',
            maxWidth: 'calc(1600px)',
            padding: [0, null, null],
            overflow: 'hidden',
            width: '100%'
        },
        fullWidth: {
            padding: [0, null, null],
            overflow: 'hidden',
            width: '100%',
            maxWidth: 'none'
        }
    },
    text: {
        primaryLarge: {
            fontFamily: 'primary',
            fontWeight: 'regular',
            fontSize: ['32px', null, '56px'],
            lineHeight: ['38px', null, '64px'],
            color: 'bistre'
        },
        primaryMedium: {
            fontFamily: 'primary',
            fontWeight: 'regular',
            fontSize: ['32px', null, '42px'],
            lineHeight: ['38px', null, '52px'],
            color: 'bistre'
        },
        primarySmall: {
            fontFamily: 'primary',
            fontWeight: 'regular',
            fontSize: ['24px', null, '32px'],
            lineHeight: ['28px', null, '38px'],
            color: 'bistre'
        },
        secondaryMedium: {
            fontFamily: 'secondary',
            fontWeight: 'bold',
            fontSize: ['24px', null, '30px'],
            lineHeight: ['32px', null, '40px'],
            color: 'bistre'
        },
        secondaryMediumBorder: {
            fontFamily: 'secondary',
            fontWeight: 'bold',
            fontSize: ['24px', null, '30px'],
            lineHeight: ['32px', null, '40px'],
            color: 'bistre',
            borderBottom: '1px solid #e3e3e3',
            paddingBottom: ['12px', null, '20px'],
            marginBottom: ['24px', null, '48px']
        },
        secondaryShMedium: {
            fontFamily: 'secondary',
            fontWeight: 'bold',
            fontSize: ['20px', null, '26px'],
            lineHeight: ['28px', null, '34px'],
            color: 'bistre'
        },
        secondarySmall: {
            fontFamily: 'secondary',
            fontWeight: 'bold',
            fontSize: ['16px', null, '18px'],
            lineHeight: ['20px', null, '24px'],
            color: 'bistre'
        },
        secondaryXSmall: {
            fontFamily: 'secondary',
            fontWeight: 'bold',
            fontSize: ['12px'],
            lineHeight: ['20px'],
            color: 'bistre'
        },
        secondaryLarge: {
            fontFamily: 'secondary',
            fontWeight: 'bold',
            fontSize: ['32px', null, '36px'],
            lineHeight: ['42px', null, '47px'],
            color: 'bistre'
        },
        secondaryXLarge: {
            fontFamily: 'secondary',
            fontWeight: 'bold',
            fontSize: ['32px', null, '48px'],
            lineHeight: ['42px', null, '56px'],
            color: 'bistre'
        },
        body: {
            fontFamily: 'body',
            lineHeight: 'body',
            fontWeight: 'body',
            fontSize: [2, null, 3]
        },
        small: {
            fontFamily: 'body',
            lineHeight: 'body',
            fontWeight: 'body',
            fontSize: '16px',
            letterSpacing: '.3px'
        },
        xsmall: {
            fontFamily: 'body',
            lineHeight: 'body',
            fontWeight: 'body',
            fontSize: '14px',
            letterSpacing: '.3px'
        },
        large: {
            fontFamily: 'body',
            lineHeight: '36px',
            fontWeight: 'body',
            fontSize: '36px'
        },
        caps: {
            textTransform: 'uppercase'
        },
        featuredCardTitle: {
            color: 'text',
            fontFamily: 'secondary',
            fontWeight: 'bold',
            fontSize: '36px',
            lineHeight: '36px'
        },
        formInput: {
            fontFamily: 'body',
            lineHeight: 'body',
            fontWeight: 'body'
        },
        formLabel: {
            fontFamily: 'body',
            lineHeight: 'body',
            fontWeight: 'body',
            textTransform: 'uppercase'
        },
        formError: {
            fontFamily: 'body',
            lineHeight: 'body',
            fontWeight: 'body',
            textTransform: 'uppercase',
            color: colors.perracotta
        },
        link: {
            color: 'inherit',
            fontFamily: 'body',
            lineHeight: 'body',
            fontWeight: 'body',
            textDecoration: 'none',
            letterSpacing: '2px',
            textTransform: 'uppercase'
        },
        h1: {
            color: 'text',
            fontFamily: 'secondary',
            lineHeight: 'heading',
            fontWeight: 'heading',
            fontSize: 5
        },
        h2: {
            color: 'text',
            fontFamily: 'heading',
            lineHeight: 'heading',
            fontWeight: 'heading',
            fontSize: 4
        },
        h3: {
            color: 'text',
            fontFamily: 'secondary',
            fontWeight: 'bold',
            fontSize: '30px',
            lineHeight: '40px'
        },
        h4: {
            color: 'text',
            fontFamily: 'heading',
            lineHeight: 'heading',
            fontWeight: 'heading',
            fontSize: 2
        },
        h5: {
            color: 'text',
            fontFamily: 'heading',
            lineHeight: 'heading',
            fontWeight: 'heading',
            fontSize: 1
        },
        collectionHeading: {
            color: 'text',
            fontFamily: 'secondary',
            fontWeight: 'bold',
            fontSize: '28px',
            lineHeight: '28px'
        },
        productTitle: {
            color: 'text',
            fontFamily: 'secondary',
            fontWeight: 'bold',
            fontSize: '44px',
            lineHeight: '48px'
        },
        footerHeading: {
            color: colors.white,
            fontFamily: 'secondary',
            fontSize: '18px',
            lineHeight: '24px',
            marginBottom: '10px',
            fontWeight: 'bold'
        },
        footerText: {
            color: colors.white,
            fontSize: '16px',
            lineHeight: '24px',
            letterSpacing: '0.025rem'
        }
    }
}

export default theme
