import { createAsyncThunk } from '@reduxjs/toolkit';
import auth from '~/services/auth'
import { clearSession, identifyError,identifySuccess,identifyRequest } from './authSlice';
import { trackUserSignedOut } from '../analytics';

export const getToken = createAsyncThunk(
    'auth/getToken',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            dispatch(identifyRequest())
            const { accessToken } = await auth.getToken();
            dispatch(identifySuccess(accessToken));
            return accessToken;
        } catch (error) {
            if (error.message === 'User is not logged in.') {
                dispatch(clearSession());
            } else {
                dispatch(identifyError(error));
                dispatch(logout());
            }
            return rejectWithValue(error); // This will be handled as a rejected action
        }
    }
);



export const logout = createAsyncThunk(
    'auth/logout',
    async (_, { dispatch }) => {
        dispatch(trackUserSignedOut());
        await auth.logout();
    }
);


