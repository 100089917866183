import storage from '~/services/storage'

export const persist = (store) => (next) => (action) => {
    const previousState = store.getState()
    const result = next(action)
    const nextState = store.getState()

    if (previousState.cart.data.id !== nextState.cart.data.id) {
        storage.setItem('cart-id', nextState.cart.data.id)
    }

    return result
}
