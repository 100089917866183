import React, {useEffect} from 'react'
import {Provider} from 'react-redux'
import createStore from '~/store/redux/store'
import {usePreloadedState} from '~/hooks/components/use-preloaded-state'
import initializeState from '~/store/redux/store/init'

const ReduxProvider = ({children}) => {
    // hydrate the Redux store with data from our GraphQL queries.


    const preloadedState = usePreloadedState()
    const store = createStore({}, preloadedState)

    useEffect(() => {
        store.dispatch(initializeState())
    }, []);
    return <Provider store={store}>{children}</Provider>
}

export default ReduxProvider
