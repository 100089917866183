import { createAsyncThunk } from "@reduxjs/toolkit";
import { forgetCart } from './cartSlice'
import { logSentryError } from '~/services/sentry';
import {getAllCartPromotionsForDisplay} from "~/utils/promotions";

export const loadCart = createAsyncThunk(
    'cart/loadCart',
    async (_, { getState, dispatch, extra }) => {
        const { cart } = getState();
        const api = extra.api;
        const createOrLoadCart = async (cartId) => {
            if (!cartId) {
                return await api.createOrder();
            }

            try {
                const cart = await api.getOrder(cartId);
                if(cart.completedAt) {
                    dispatch(forgetCart());
                    return await api.createOrder();
                }
                return cart;
            } catch (error) {
                console.log('error', error)
                dispatch(forgetCart());
                return await api.createOrder();
            }
        };
        return await createOrLoadCart(cart.data.id);
    }
);


export const getOrLoadCart = (dispatch, getState) => {
    let {
        cart
    } = getState()
    if (!cart.data.id) {

        try {
            dispatch(loadCart())
        } catch (error) {
            console.log('error', error)
            logSentryError(error, {
                source: 'getOrLoadCart'
            })
        }

        cart = getState().cart
    }
    return cart
}

export const addToCart = createAsyncThunk(
    'cart/addToCart',
    async ({ variantId, quantity, placement, position, customAttributes }, { dispatch, getState, extra }) => {
        const api = extra.api;

        const cart = getOrLoadCart(dispatch, getState);
        const { user } = getState();

        try {
            const updatedCart = await api.addOrderItem(cart.data.id, {
                variantId,
                quantity,
                user: user.data,
                placement,
                position,
                customAttributes,
            });

            const globalVariantId = `gid://shopify/ProductVariant/${variantId}`;
            const addLineItem = updatedCart.lineItems.find(
                (li) => li.variant.id === globalVariantId
            );

            return {
                data: updatedCart,
                variant: addLineItem.variant,
                quantity
            };
        } catch (error) {
            console.log('error', error)
            logSentryError(error, {
                source: 'addToCart'
            })
        }
    }
);

export const applyGiftCard = createAsyncThunk(
    'cart/applyGiftCard',
    async (giftCardCode, { dispatch, getState, extra }) => {
        const api = extra.api;

        const cart = getOrLoadCart(dispatch, getState);

        const updatedCart = await api.applyGiftCard(cart.data.id, giftCardCode);
        return { data: updatedCart };
    }
);

export const removeGiftCard = createAsyncThunk(
    'cart/removeGiftCard',
    async (giftCardCode, { dispatch, getState, extra }) => {
        const api = extra.api;

        const cart = getOrLoadCart(dispatch, getState);

        const updatedCart = await api.removeGiftCard(cart.data.id, giftCardCode);
        return { data: updatedCart };
    })

export const removePromoCode = createAsyncThunk(
    'cart/removePromoCode',
    async (promoCode, { dispatch, getState, extra }) => {
        const api = extra.api;

        const cart = getOrLoadCart(dispatch, getState);

        // Only support one promotion per order for now
        const promotions = getAllCartPromotionsForDisplay(cart);
        const promoAmount = promotions ? promotions[0].amount : 0;

        const updatedCart = await api.removePromoCode(cart.data.id, promoCode);

        return { data: updatedCart, promoCode, promoAmount };
    }
);

export const addPromoCode = createAsyncThunk(
    'cart/addPromoCode',
    async (promoCode, { dispatch, getState, extra }) => {
        const api = extra.api;
        const cart = getOrLoadCart(dispatch, getState);
        const updatedCart = await api.addPromoCode(cart.data.id, promoCode);

        return { data: updatedCart, promoCode };
    }
);

export const checkout = createAsyncThunk(
    'cart/checkout',
    async (_, { dispatch, getState, extra }) => {
        const api = extra.api;
        const { auth, user } = getState();

        const cart = getOrLoadCart(dispatch, getState);

        await api.checkout(cart.data.id, auth.token, user.data);
        return cart.data;
    }
);

const getLineItemById = (cart, id) =>
    cart.data.lineItems.find((lineItem) => lineItem.id === id)

export const removeFromCart = createAsyncThunk(
    'cart/removeFromCart',
    async (lineItemId, { dispatch, getState, extra }) => {
        const api = extra.api;

        const cart = getOrLoadCart(dispatch, getState);
        const { user } = getState();

        const updatedCart = await api.removeOrderItem(cart.data.id, {
            lineItemId,
            user: user.data,
        });

        const removedLineItem = getLineItemById(cart, lineItemId);
        const variant = removedLineItem ? removedLineItem.variant : null;
        const quantity = removedLineItem ? -removedLineItem.quantity : 0;

        return { data: updatedCart, variant, quantity };
    }
);

export const modifyLineItem = createAsyncThunk(
    'cart/modifyLineItem',
    async ({ variantId, quantity, customAttributes }, { dispatch, getState, extra }) => {
        const api = extra.api;
        const cart = getOrLoadCart(dispatch, getState);
        const { user } = getState();

        const updatedCart = await api.updateOrderItem(cart.data.id, {
            lineItemId: variantId,
            quantity,
            user: user.data,
            customAttributes,
        });

        const modifiedLineItem = getLineItemById(cart, variantId);
        return { data: updatedCart, variant: modifiedLineItem.variant };
    }
);

export const modifyQuantity = createAsyncThunk(
    'cart/modifyQuantity',
    async ({ lineItemId, quantity }, { dispatch, getState, extra }) => {
        const api = extra.api;
        const cart = getOrLoadCart(dispatch, getState);
        const {  user } = getState();

        const updatedCart = await api.updateOrderItem(cart.data.id, {
            lineItemId,
            quantity,
            user: user.data,
        });
        const modifiedLineItem = getLineItemById(cart, lineItemId);
        const updatedQuantity = quantity - modifiedLineItem.quantity;
        return { data: updatedCart, variant: modifiedLineItem.variant, updatedQuantity };
    }
);



