import createRequest from "./request";
import OMSError from "./error";

export const formatVariantId = (variantId) => {
    if (typeof variantId !== "string") {
        console.warn("variantId should be a string, this type will be changed in a future version");
    }
    return `gid://shopify/ProductVariant/${variantId}`;
};

export const formatCustomerId = (customerId) => {
    return `gid://shopify/Customer/${customerId}`;
};

export function mapNodes(connection, transform) {
    if (!connection) {
        return [];
    }
    return connection.edges.map(({ node }) => transform ? transform(node) : node);
}

const pluckAmount = (moneyV2) => moneyV2.amount;

export const transformGraphProductWithVariants = (product) => ({
    ...product,
    images: mapNodes(product.images),
});

export const transformGraphVariant = (variant) => ({
    ...variant,
    price: pluckAmount(variant.priceV2),
    product: transformGraphProductWithVariants(variant.product),
});

export const transformGraphCheckout = (checkout) => ({
    ...checkout,
    lineItems: mapNodes(checkout.lineItems, (node) => ({
        ...node,
        variant: transformGraphVariant(node.variant),
    })),
    discountApplications: mapNodes(checkout.discountApplications),
    totalTax: pluckAmount(checkout.totalTaxV2),
    subtotalPrice: pluckAmount(checkout.subtotalPriceV2),
    totalPrice: pluckAmount(checkout.totalPriceV2),
});

export const transformGraphCustomer = (customer) => ({
    ...customer,
    addresses: mapNodes(customer.addresses),
    orders: mapNodes(customer.orders),
});

let request = null;

export const createRunQuery = (config) => {
    if (!request) request = createRequest(config);
    return async (query, variables) => {
        const response = await request.post("", {
            query,
            variables,
        });
        if (response.data.errors && response.data.errors.length) {
            throw new OMSError(response.data.errors[0]);
        }
        return response;
    };
};

export const getObjectValueAtPathOrNull = (object, pathToValue) => {
    return pathToValue.split(".").reduce((acc, key) => {
        return acc && acc[key] ? acc[key] : null;
    }, object);
};

export const runQueryPluckAndTranslate = async (
    runQuery,
    query,
    variables,
    pathToPluck,
    pathToErrors,
    translate
) => {
    const response = await runQuery(query, variables);
    if (response.data.errors && response.data.errors.length) {
        throw new OMSError(response.data.errors[0]);
    }
    const errors = pathToErrors ? getObjectValueAtPathOrNull(response, pathToErrors) : null;
    if (errors && errors.length) {
        throw new OMSError(errors[0]);
    }
    const plucked = pathToPluck ? getObjectValueAtPathOrNull(response, pathToPluck) : null;
    if (!plucked) {
        throw new OMSError("An unexpected error occurred.");
    }
    return translate ? translate(plucked) : plucked;
};
