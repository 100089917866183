/**
 * This client was automatically generated by Segment Typewriter. ** Do Not Edit **
 */

/**
 * The default handler that is fired if none is supplied with setTypewriterOptions.
 * This handler will log a warning message to the console.
 */
export const  defaultValidationErrorHandler = (message, violations) => {
    const msg = JSON.stringify({
        type: 'Typewriter JSON Schema Validation Error',
        description: `You made an analytics call (${message.event}) using Typewriter that doesn't match the Tracking Plan spec.`,
        errors: violations
    }, undefined, 2);
    console.warn(msg);
};

let analytics = ()   => window.analytics;

/**
 * Updates the run-time configuration of this Typewriter client.
 *
 * @param {TypewriterOptions} options - the options to upsert
 */
export const setTypewriterOptions = (options) => {
    analytics = options.analytics ? function () {
        return options.analytics || window.analytics;
    } : analytics;
}

/**
 * Helper to attach metadata on Typewriter to outbound requests.
 * This is used for attribution and debugging by the Segment team.
 */
const  withTypewriterContext = (message = {}) => {
    return {
        ...message,
        context: {
            ...message.context,
            typewriter: {
                language: 'typescript',
                version: '7.4.1'
            }
        }
    };
}
/**
 * @typedef Ownership
 * @property {string} store_id -
 * @property {string} tenant_id -
 */
/**
 * @typedef Platform
 * @property {string} name -
 * @property {string} type -
 */
/**
 * @typedef Store
 * @property {string} domain -
 */
/**
 * @typedef Version
 * @property {number} major -
 * @property {number} minor -
 * @property {number} patch -
 */
/**
 * @typedef Meta
 * @property {Ownership} ownership -
 * @property {Platform} platform -
 * @property {Store} store -
 * @property {Version} version -
 */
/**
 * @typedef ProductsItem
 * @property {string} [product_description] -
 * @property {string} product_id -
 * @property {string} product_image_url -
 * @property {string} product_name -
 * @property {string} product_slug -
 * @property {number} [quantity] -
 */
/**
 * @typedef CartViewed
 * @property {string} currency -
 * @property {Meta} meta -
 * @property {string} [order_id] -
 * @property {string} [order_name] -
 * @property {ProductsItem[]} [products] -
 * @property {string} [user_display_name] -
 * @property {string} [user_email] -
 * @property {string} [user_first_name] -
 * @property {string} [user_id] -
 * @property {string} [user_last_name] -
 * @property {string} [user_phone] -
 */
/**
 * @typedef Ownership1
 * @property {string} store_id -
 * @property {string} tenant_id -
 */
/**
 * @typedef Platform1
 * @property {string} name -
 * @property {string} type -
 */
/**
 * @typedef Store1
 * @property {string} domain -
 */
/**
 * @typedef Version1
 * @property {number} major -
 * @property {number} minor -
 * @property {number} patch -
 */
/**
 * @typedef Meta1
 * @property {Ownership1} ownership -
 * @property {Platform1} platform -
 * @property {Store1} store -
 * @property {Version1} version -
 */
/**
 * @typedef ProductsItem1
 * @property {string} [product_description] -
 * @property {string} product_id -
 * @property {string} product_image_url -
 * @property {string} product_name -
 * @property {string} product_slug -
 * @property {number} [quantity] -
 */
/**
 * @typedef TrackingIdsItem
 * @property {Record<string, any>} [id] -
 * @property {Record<string, any>} [platform] -
 */
/**
 * @typedef CheckoutCompleted
 * @property {string} [coupon_id] -
 * @property {string} coupon_name -
 * @property {Meta1} meta -
 * @property {number} order_discount_total -
 * @property {string} order_id -
 * @property {string} order_name -
 * @property {number} order_shipping_total -
 * @property {number} order_tax_total -
 * @property {number} order_total -
 * @property {ProductsItem1[]} [products] -
 * @property {TrackingIdsItem[]} [tracking_ids] -
 * @property {string} [user_display_name] -
 * @property {string} [user_email] -
 * @property {string} [user_first_name] -
 * @property {string} user_id -
 * @property {string} [user_last_name] -
 * @property {string} [user_phone] -
 */
/**
 * @typedef Ownership2
 * @property {string} store_id -
 * @property {string} tenant_id -
 */
/**
 * @typedef Platform2
 * @property {string} name -
 * @property {string} type -
 */
/**
 * @typedef Store2
 * @property {string} domain -
 */
/**
 * @typedef Version2
 * @property {number} major -
 * @property {number} minor -
 * @property {number} patch -
 */
/**
 * @typedef Meta2
 * @property {Ownership2} ownership -
 * @property {Platform2} platform -
 * @property {Store2} store -
 * @property {Version2} version -
 */
/**
 * @typedef ProductsItem2
 * @property {string} [product_description] -
 * @property {string} product_id -
 * @property {string} product_image_url -
 * @property {string} product_name -
 * @property {string} product_slug -
 * @property {number} [quantity] -
 */
/**
 * @typedef CheckoutStarted
 * @property {Meta2} meta -
 * @property {string} [order_id] -
 * @property {string} [order_name] -
 * @property {ProductsItem2[]} [products] -
 * @property {string} [user_display_name] -
 * @property {string} [user_email] -
 * @property {string} [user_first_name] -
 * @property {string} [user_id] -
 * @property {string} [user_last_name] -
 * @property {string} [user_phone] -
 */
/**
 * @typedef Ownership3
 * @property {string} store_id -
 * @property {string} tenant_id -
 */
/**
 * @typedef Platform3
 * @property {string} name -
 * @property {string} type -
 */
/**
 * @typedef Store3
 * @property {string} domain -
 */
/**
 * @typedef Version3
 * @property {number} major -
 * @property {number} minor -
 * @property {number} patch -
 */
/**
 * @typedef Meta3
 * @property {Ownership3} ownership -
 * @property {Platform3} platform -
 * @property {Store3} store -
 * @property {Version3} version -
 */
/**
 * @typedef CollectionClicked
 * @property {string} [collection_description] -
 * @property {string} collection_id -
 * @property {string} [collection_image_url] -
 * @property {string} [collection_slug] -
 * @property {string} [collection_title] -
 * @property {Meta3} meta -
 */
/**
 * @typedef Ownership4
 * @property {string} store_id -
 * @property {string} tenant_id -
 */
/**
 * @typedef Platform4
 * @property {string} name -
 * @property {string} type -
 */
/**
 * @typedef Store4
 * @property {string} domain -
 */
/**
 * @typedef Version4
 * @property {number} major -
 * @property {number} minor -
 * @property {number} patch -
 */
/**
 * @typedef Meta4
 * @property {Ownership4} ownership -
 * @property {Platform4} platform -
 * @property {Store4} store -
 * @property {Version4} version -
 */
/**
 * @typedef CouponApplied
 * @property {string} [coupon_id] -
 * @property {string} coupon_name -
 * @property {Meta4} meta -
 */
/**
 * @typedef Ownership5
 * @property {string} store_id -
 * @property {string} tenant_id -
 */
/**
 * @typedef Platform5
 * @property {string} name -
 * @property {string} type -
 */
/**
 * @typedef Store5
 * @property {string} domain -
 */
/**
 * @typedef Version5
 * @property {number} major -
 * @property {number} minor -
 * @property {number} patch -
 */
/**
 * @typedef Meta5
 * @property {Ownership5} ownership -
 * @property {Platform5} platform -
 * @property {Store5} store -
 * @property {Version5} version -
 */
/**
 * @typedef CouponDenied
 * @property {string} [coupon_id] -
 * @property {string} coupon_name -
 * @property {Meta5} meta -
 * @property {string} reason -
 */
/**
 * @typedef Ownership6
 * @property {string} store_id -
 * @property {string} tenant_id -
 */
/**
 * @typedef Platform6
 * @property {string} name -
 * @property {string} type -
 */
/**
 * @typedef Store6
 * @property {string} domain -
 */
/**
 * @typedef Version6
 * @property {number} major -
 * @property {number} minor -
 * @property {number} patch -
 */
/**
 * @typedef Meta6
 * @property {Ownership6} ownership -
 * @property {Platform6} platform -
 * @property {Store6} store -
 * @property {Version6} version -
 */
/**
 * @typedef CouponEntered
 * @property {string} [coupon_id] -
 * @property {string} coupon_name -
 * @property {Meta6} meta -
 */
/**
 * @typedef Ownership7
 * @property {string} store_id -
 * @property {string} tenant_id -
 */
/**
 * @typedef Platform7
 * @property {string} name -
 * @property {string} type -
 */
/**
 * @typedef Store7
 * @property {string} domain -
 */
/**
 * @typedef Version7
 * @property {number} major -
 * @property {number} minor -
 * @property {number} patch -
 */
/**
 * @typedef Meta7
 * @property {Ownership7} ownership -
 * @property {Platform7} platform -
 * @property {Store7} store -
 * @property {Version7} version -
 */
/**
 * @typedef CouponRemoved
 * @property {string} [coupon_id] -
 * @property {string} coupon_name -
 * @property {Meta7} meta -
 */
/**
 * @typedef Ownership8
 * @property {string} store_id -
 * @property {string} tenant_id -
 */
/**
 * @typedef Platform8
 * @property {string} name -
 * @property {string} type -
 */
/**
 * @typedef Store8
 * @property {string} domain -
 */
/**
 * @typedef Version8
 * @property {number} major -
 * @property {number} minor -
 * @property {number} patch -
 */
/**
 * @typedef Meta8
 * @property {Ownership8} ownership -
 * @property {Platform8} platform -
 * @property {Store8} store -
 * @property {Version8} version -
 */
/**
 * @typedef EmailCaptured
 * @property {string} email -
 * @property {Meta8} [meta] -
 * @property {string} [placement_component] -
 * @property {string} [placement_page] -
 */
/**
 * @typedef Ownership9
 * @property {string} store_id -
 * @property {string} tenant_id -
 */
/**
 * @typedef Platform9
 * @property {string} name -
 * @property {string} type -
 */
/**
 * @typedef Store9
 * @property {string} domain -
 */
/**
 * @typedef Version9
 * @property {number} major -
 * @property {number} minor -
 * @property {number} patch -
 */
/**
 * @typedef Meta9
 * @property {Ownership9} ownership -
 * @property {Platform9} platform -
 * @property {Store9} store -
 * @property {Version9} version -
 */
/**
 * @typedef GiftCardApplied
 * @property {string} gift_card_id -
 * @property {string} [gift_card_last_characters] -
 * @property {Meta9} meta -
 */
/**
 * @typedef Ownership10
 * @property {string} store_id -
 * @property {string} tenant_id -
 */
/**
 * @typedef Platform10
 * @property {string} name -
 * @property {string} type -
 */
/**
 * @typedef Store10
 * @property {string} domain -
 */
/**
 * @typedef Version10
 * @property {number} major -
 * @property {number} minor -
 * @property {number} patch -
 */
/**
 * @typedef Meta10
 * @property {Ownership10} ownership -
 * @property {Platform10} platform -
 * @property {Store10} store -
 * @property {Version10} version -
 */
/**
 * @typedef GiftCardDenied
 * @property {string} [gift_card_last_characters] -
 * @property {Meta10} meta -
 * @property {string} reason -
 */
/**
 * @typedef Ownership11
 * @property {string} store_id -
 * @property {string} tenant_id -
 */
/**
 * @typedef Platform11
 * @property {string} name -
 * @property {string} type -
 */
/**
 * @typedef Store11
 * @property {string} domain -
 */
/**
 * @typedef Version11
 * @property {number} major -
 * @property {number} minor -
 * @property {number} patch -
 */
/**
 * @typedef Meta11
 * @property {Ownership11} ownership -
 * @property {Platform11} platform -
 * @property {Store11} store -
 * @property {Version11} version -
 */
/**
 * @typedef GiftCardEntered
 * @property {string} [gift_card_last_characters] -
 * @property {Meta11} meta -
 */
/**
 * @typedef Ownership12
 * @property {string} store_id -
 * @property {string} tenant_id -
 */
/**
 * @typedef Platform12
 * @property {string} name -
 * @property {string} type -
 */
/**
 * @typedef Store12
 * @property {string} domain -
 */
/**
 * @typedef Version12
 * @property {number} major -
 * @property {number} minor -
 * @property {number} patch -
 */
/**
 * @typedef Meta12
 * @property {Ownership12} ownership -
 * @property {Platform12} platform -
 * @property {Store12} store -
 * @property {Version12} version -
 */
/**
 * @typedef GiftCardRemoved
 * @property {string} gift_card_id -
 * @property {string} [gift_card_last_characters] -
 * @property {Meta12} meta -
 */
/**
 * @typedef Ownership13
 * @property {string} store_id -
 * @property {string} tenant_id -
 */
/**
 * @typedef Platform13
 * @property {string} name -
 * @property {string} type -
 */
/**
 * @typedef Store13
 * @property {string} domain -
 */
/**
 * @typedef Version13
 * @property {number} major -
 * @property {number} minor -
 * @property {number} patch -
 */
/**
 * @typedef Meta13
 * @property {Ownership13} ownership -
 * @property {Platform13} platform -
 * @property {Store13} store -
 * @property {Version13} version -
 */
/**
 * @typedef NavigationClicked
 * @property {string} [category] -
 * @property {string} [label] -
 * @property {Meta13} meta -
 * @property {string} [navigation_placement] -
 * @property {string} navigation_title -
 * @property {string} navigation_url -
 */
/**
 * @typedef Ownership14
 * @property {string} store_id -
 * @property {string} tenant_id -
 */
/**
 * @typedef Platform14
 * @property {string} name -
 * @property {string} type -
 */
/**
 * @typedef Store14
 * @property {string} domain -
 */
/**
 * @typedef Version14
 * @property {number} major -
 * @property {number} minor -
 * @property {number} patch -
 */
/**
 * @typedef Meta14
 * @property {Ownership14} ownership -
 * @property {Platform14} platform -
 * @property {Store14} store -
 * @property {Version14} version -
 */
/**
 * @typedef ProductsItem3
 * @property {string} [product_description] -
 * @property {string} product_id -
 * @property {string} product_image_url -
 * @property {string} product_name -
 * @property {number} [product_quantity] -
 * @property {string} product_slug -
 * @property {string} [product_variant_id] -
 * @property {string} [product_variant_name] -
 * @property {number} [product_variant_price] -
 * @property {string} [product_variant_sku] -
 */
/**
 * @typedef OrderCompleted
 * @property {string} [coupon_id] -
 * @property {string} coupon_name -
 * @property {Meta14} meta -
 * @property {number} order_discount_total -
 * @property {string} order_id -
 * @property {string} order_name -
 * @property {number} order_shipping_total -
 * @property {number} order_tax_total -
 * @property {number} order_total -
 * @property {ProductsItem3[]} [products] -
 * @property {string} [user_display_name] -
 * @property {string} [user_email] -
 * @property {string} [user_first_name] -
 * @property {string} user_id -
 * @property {string} [user_last_name] -
 * @property {string} [user_phone] -
 */
/**
 * @typedef Ownership15
 * @property {string} store_id -
 * @property {string} tenant_id -
 */
/**
 * @typedef Platform15
 * @property {string} name -
 * @property {string} type -
 */
/**
 * @typedef Store15
 * @property {string} domain -
 */
/**
 * @typedef Version15
 * @property {number} major -
 * @property {number} minor -
 * @property {number} patch -
 */
/**
 * @typedef Meta15
 * @property {Ownership15} ownership -
 * @property {Platform15} platform -
 * @property {Store15} store -
 * @property {Version15} version -
 */
/**
 * @typedef ProductAdded
 * @property {string} currency -
 * @property {Meta15} meta -
 * @property {string} [order_id] -
 * @property {string} [order_name] -
 * @property {string} [product_description] -
 * @property {string} product_id -
 * @property {string} product_image_url -
 * @property {string} product_name -
 * @property {string} [product_placement] - A custom string that describes a particular location on the frontend from where the product has been added.
 * @property {number} [product_position] -
 * @property {number} product_quantity -
 * @property {string} product_slug -
 * @property {string} [user_display_name] -
 * @property {string} [user_email] -
 * @property {string} [user_first_name] -
 * @property {string} [user_id] -
 * @property {string} [user_last_name] -
 * @property {string} [user_phone] -
 */
/**
 * @typedef Ownership16
 * @property {string} store_id -
 * @property {string} tenant_id -
 */
/**
 * @typedef Platform16
 * @property {string} name -
 * @property {string} type -
 */
/**
 * @typedef Store16
 * @property {string} domain -
 */
/**
 * @typedef Version16
 * @property {number} major -
 * @property {number} minor -
 * @property {number} patch -
 */
/**
 * @typedef Meta16
 * @property {Ownership16} ownership -
 * @property {Platform16} platform -
 * @property {Store16} store -
 * @property {Version16} version -
 */
/**
 * @typedef ProductClicked
 * @property {Meta16} meta -
 * @property {string} [order_id] -
 * @property {string} [order_name] -
 * @property {string} [product_description] -
 * @property {string} product_id -
 * @property {string} product_image_url -
 * @property {string} product_name -
 * @property {string} [product_placement] - A custom string that describes a particular location on the frontend from where the product has been clicked.
 * @property {number} [product_position] -
 * @property {string} product_slug -
 * @property {string} [user_display_name] -
 * @property {string} [user_email] -
 * @property {string} [user_first_name] -
 * @property {string} [user_id] -
 * @property {string} [user_last_name] -
 * @property {string} [user_phone] -
 */
/**
 * @typedef Ownership17
 * @property {string} store_id -
 * @property {string} tenant_id -
 */
/**
 * @typedef Platform17
 * @property {string} name -
 * @property {string} type -
 */
/**
 * @typedef Store17
 * @property {string} domain -
 */
/**
 * @typedef Version17
 * @property {number} major -
 * @property {number} minor -
 * @property {number} patch -
 */
/**
 * @typedef Meta17
 * @property {Ownership17} ownership -
 * @property {Platform17} platform -
 * @property {Store17} store -
 * @property {Version17} version -
 */
/**
 * @typedef ProductDetailsClicked
 * @property {Meta17} meta -
 * @property {string} [product_description] -
 * @property {string} product_id -
 * @property {string} product_image_url -
 * @property {string} product_name -
 * @property {string} product_slug -
 * @property {string} title - The title or name of the tab, section, panel etc that was clicked
 */
/**
 * @typedef Ownership18
 * @property {string} store_id -
 * @property {string} tenant_id -
 */
/**
 * @typedef Platform18
 * @property {string} name -
 * @property {string} type -
 */
/**
 * @typedef Store18
 * @property {string} domain -
 */
/**
 * @typedef Version18
 * @property {number} major -
 * @property {number} minor -
 * @property {number} patch -
 */
/**
 * @typedef Meta18
 * @property {Ownership18} ownership -
 * @property {Platform18} platform -
 * @property {Store18} store -
 * @property {Version18} version -
 */
/**
 * @typedef ProductListFiltered
 * @property {Meta18} meta -
 * @property {string} product_list_category -
 * @property {string} product_list_filter_type -
 * @property {string} product_list_filter_value -
 * @property {string} product_list_id -
 * @property {string} product_list_sort_type -
 * @property {string} product_list_sort_value -
 */
/**
 * @typedef Ownership19
 * @property {string} store_id -
 * @property {string} tenant_id -
 */
/**
 * @typedef Platform19
 * @property {string} name -
 * @property {string} type -
 */
/**
 * @typedef Store19
 * @property {string} domain -
 */
/**
 * @typedef Version19
 * @property {number} major -
 * @property {number} minor -
 * @property {number} patch -
 */
/**
 * @typedef Meta19
 * @property {Ownership19} ownership -
 * @property {Platform19} platform -
 * @property {Store19} store -
 * @property {Version19} version -
 */
/**
 * @typedef ProductListViewed
 * @property {Meta19} meta -
 * @property {string} product_list_category -
 * @property {string} product_list_id -
 */
/**
 * @typedef Ownership20
 * @property {string} store_id -
 * @property {string} tenant_id -
 */
/**
 * @typedef Platform20
 * @property {string} name -
 * @property {string} type -
 */
/**
 * @typedef Store20
 * @property {string} domain -
 */
/**
 * @typedef Version20
 * @property {number} major -
 * @property {number} minor -
 * @property {number} patch -
 */
/**
 * @typedef Meta20
 * @property {Ownership20} ownership -
 * @property {Platform20} platform -
 * @property {Store20} store -
 * @property {Version20} version -
 */
/**
 * @typedef ProductRemoved
 * @property {string} currency -
 * @property {Meta20} meta -
 * @property {string} [order_id] -
 * @property {string} [order_name] -
 * @property {string} [product_description] -
 * @property {string} product_id -
 * @property {string} product_image_url -
 * @property {string} product_name -
 * @property {number} product_quantity -
 * @property {string} product_slug -
 * @property {string} [user_display_name] -
 * @property {string} [user_email] -
 * @property {string} [user_first_name] -
 * @property {string} [user_id] -
 * @property {string} [user_last_name] -
 * @property {string} [user_phone] -
 */
/**
 * @typedef Ownership21
 * @property {string} store_id -
 * @property {string} tenant_id -
 */
/**
 * @typedef Platform21
 * @property {string} name -
 * @property {string} type -
 */
/**
 * @typedef Store21
 * @property {string} domain -
 */
/**
 * @typedef Version21
 * @property {number} major -
 * @property {number} minor -
 * @property {number} patch -
 */
/**
 * @typedef Meta21
 * @property {Ownership21} ownership -
 * @property {Platform21} platform -
 * @property {Store21} store -
 * @property {Version21} version -
 */
/**
 * @typedef ProductReviewed
 * @property {Meta21} [meta] -
 * @property {string} [product_id] -
 * @property {string} review_body -
 * @property {string} review_id -
 * @property {number} review_rating -
 * @property {string} [user_email] -
 * @property {string} [variant_id] -
 */
/**
 * @typedef Ownership22
 * @property {string} store_id -
 * @property {string} tenant_id -
 */
/**
 * @typedef Platform22
 * @property {string} name -
 * @property {string} type -
 */
/**
 * @typedef Store22
 * @property {string} domain -
 */
/**
 * @typedef Version22
 * @property {number} major -
 * @property {number} minor -
 * @property {number} patch -
 */
/**
 * @typedef Meta22
 * @property {Ownership22} ownership -
 * @property {Platform22} platform -
 * @property {Store22} store -
 * @property {Version22} version -
 */
/**
 * @typedef ProductUpdated
 * @property {string} currency -
 * @property {Meta22} meta -
 * @property {string} [order_id] -
 * @property {string} [order_name] -
 * @property {string} [product_description] -
 * @property {string} product_id -
 * @property {string} product_image_url -
 * @property {string} product_name -
 * @property {number} product_quantity -
 * @property {string} product_slug -
 * @property {string} [user_display_name] -
 * @property {string} [user_email] -
 * @property {string} [user_first_name] -
 * @property {string} [user_id] -
 * @property {string} [user_last_name] -
 * @property {string} [user_phone] -
 */
/**
 * @typedef Ownership23
 * @property {string} store_id -
 * @property {string} tenant_id -
 */
/**
 * @typedef Platform23
 * @property {string} name -
 * @property {string} type -
 */
/**
 * @typedef Store23
 * @property {string} domain -
 */
/**
 * @typedef Version23
 * @property {number} major -
 * @property {number} minor -
 * @property {number} patch -
 */
/**
 * @typedef Meta23
 * @property {Ownership23} ownership -
 * @property {Platform23} platform -
 * @property {Store23} store -
 * @property {Version23} version -
 */
/**
 * @typedef ProductViewed
 * @property {Meta23} meta -
 * @property {string} [order_id] -
 * @property {string} [order_name] -
 * @property {string} [product_description] -
 * @property {string} product_id -
 * @property {string} product_image_url -
 * @property {string} product_name -
 * @property {string} product_slug -
 * @property {string} [user_display_name] -
 * @property {string} [user_email] -
 * @property {string} [user_first_name] -
 * @property {string} [user_id] -
 * @property {string} [user_last_name] -
 * @property {string} [user_phone] -
 */
/**
 * @typedef Ownership24
 * @property {string} store_id -
 * @property {string} tenant_id -
 */
/**
 * @typedef Platform24
 * @property {string} name -
 * @property {string} type -
 */
/**
 * @typedef Store24
 * @property {string} domain -
 */
/**
 * @typedef Version24
 * @property {number} major -
 * @property {number} minor -
 * @property {number} patch -
 */
/**
 * @typedef Meta24
 * @property {Ownership24} ownership -
 * @property {Platform24} platform -
 * @property {Store24} store -
 * @property {Version24} version -
 */
/**
 * @typedef PromotionClicked
 * @property {Meta24} meta -
 * @property {string} [promotion_creative] -
 * @property {string} promotion_id -
 * @property {string} [promotion_name] -
 * @property {string} [promotion_position] -
 * @property {string} [user_display_name] -
 * @property {string} [user_email] -
 * @property {string} [user_first_name] -
 * @property {string} [user_id] -
 * @property {string} [user_last_name] -
 * @property {string} [user_phone] -
 */
/**
 * @typedef Ownership25
 * @property {string} store_id -
 * @property {string} tenant_id -
 */
/**
 * @typedef Platform25
 * @property {string} name -
 * @property {string} type -
 */
/**
 * @typedef Store25
 * @property {string} domain -
 */
/**
 * @typedef Version25
 * @property {number} major -
 * @property {number} minor -
 * @property {number} patch -
 */
/**
 * @typedef Meta25
 * @property {Ownership25} ownership -
 * @property {Platform25} platform -
 * @property {Store25} store -
 * @property {Version25} version -
 */
/**
 * @typedef PromotionViewed
 * @property {Meta25} meta -
 * @property {string} [promotion_creative] -
 * @property {string} promotion_id -
 * @property {string} [promotion_name] -
 * @property {string} [promotion_position] -
 * @property {string} [user_display_name] -
 * @property {string} [user_email] -
 * @property {string} [user_first_name] -
 * @property {string} [user_id] -
 * @property {string} [user_last_name] -
 * @property {string} [user_phone] -
 */
/**
 * @typedef Ownership26
 * @property {string} store_id -
 * @property {string} tenant_id -
 */
/**
 * @typedef Platform26
 * @property {string} name -
 * @property {string} type -
 */
/**
 * @typedef Store26
 * @property {string} domain -
 */
/**
 * @typedef Version26
 * @property {number} major -
 * @property {number} minor -
 * @property {number} patch -
 */
/**
 * @typedef Meta26
 * @property {Ownership26} ownership -
 * @property {Platform26} platform -
 * @property {Store26} store -
 * @property {Version26} version -
 */
/**
 * @typedef StockRequestCreated
 * @property {string} email -
 * @property {Meta26} meta -
 * @property {string} [product_description] -
 * @property {string} product_id -
 * @property {string} product_image_url -
 * @property {string} product_name -
 * @property {string} product_slug -
 */
/**
 * @typedef Ownership27
 * @property {string} store_id -
 * @property {string} tenant_id -
 */
/**
 * @typedef Platform27
 * @property {string} name -
 * @property {string} type -
 */
/**
 * @typedef Store27
 * @property {string} domain -
 */
/**
 * @typedef Version27
 * @property {number} major -
 * @property {number} minor -
 * @property {number} patch -
 */
/**
 * @typedef Meta27
 * @property {Ownership27} ownership -
 * @property {Platform27} platform -
 * @property {Store27} store -
 * @property {Version27} version -
 */
/**
 * @typedef UserAddressAdded
 * @property {string} [address_address_1] -
 * @property {string} [address_address_2] -
 * @property {string} [address_city] -
 * @property {string} [address_company] -
 * @property {string} [address_country] -
 * @property {string} [address_first_name] -
 * @property {string} address_id -
 * @property {boolean} [address_is_default] -
 * @property {string} [address_last_name] -
 * @property {string} [address_name] -
 * @property {string} [address_phone] -
 * @property {string} [address_state] -
 * @property {string} [address_zip] -
 * @property {Meta27} meta -
 * @property {string} [user_display_name] -
 * @property {string} [user_email] -
 * @property {string} [user_first_name] -
 * @property {string} user_id -
 * @property {string} [user_last_name] -
 * @property {string} [user_phone] -
 */
/**
 * @typedef Ownership28
 * @property {string} store_id -
 * @property {string} tenant_id -
 */
/**
 * @typedef Platform28
 * @property {string} name -
 * @property {string} type -
 */
/**
 * @typedef Store28
 * @property {string} domain -
 */
/**
 * @typedef Version28
 * @property {number} major -
 * @property {number} minor -
 * @property {number} patch -
 */
/**
 * @typedef Meta28
 * @property {Ownership28} ownership -
 * @property {Platform28} platform -
 * @property {Store28} store -
 * @property {Version28} version -
 */
/**
 * @typedef UserAddressRemoved
 * @property {string} [address_address_1] -
 * @property {string} [address_address_2] -
 * @property {string} [address_city] -
 * @property {string} [address_company] -
 * @property {string} [address_country] -
 * @property {string} [address_first_name] -
 * @property {string} address_id -
 * @property {boolean} [address_is_default] -
 * @property {string} [address_last_name] -
 * @property {string} [address_name] -
 * @property {string} [address_phone] -
 * @property {string} [address_state] -
 * @property {string} [address_zip] -
 * @property {Meta28} meta -
 * @property {string} [user_display_name] -
 * @property {string} [user_email] -
 * @property {string} [user_first_name] -
 * @property {string} user_id -
 * @property {string} [user_last_name] -
 * @property {string} [user_phone] -
 */
/**
 * @typedef Ownership29
 * @property {string} store_id -
 * @property {string} tenant_id -
 */
/**
 * @typedef Platform29
 * @property {string} name -
 * @property {string} type -
 */
/**
 * @typedef Store29
 * @property {string} domain -
 */
/**
 * @typedef Version29
 * @property {number} major -
 * @property {number} minor -
 * @property {number} patch -
 */
/**
 * @typedef Meta29
 * @property {Ownership29} ownership -
 * @property {Platform29} platform -
 * @property {Store29} store -
 * @property {Version29} version -
 */
/**
 * @typedef UserAddressUpdated
 * @property {string} [address_address_1] -
 * @property {string} [address_address_2] -
 * @property {string} [address_city] -
 * @property {string} [address_company] -
 * @property {string} [address_country] -
 * @property {string} [address_first_name] -
 * @property {string} address_id -
 * @property {boolean} [address_is_default] -
 * @property {string} [address_last_name] -
 * @property {string} [address_name] -
 * @property {string} [address_phone] -
 * @property {string} [address_state] -
 * @property {string} [address_zip] -
 * @property {Meta29} meta -
 * @property {string} [user_display_name] -
 * @property {string} [user_email] -
 * @property {string} [user_first_name] -
 * @property {string} user_id -
 * @property {string} [user_last_name] -
 * @property {string} [user_phone] -
 */
/**
 * @typedef Ownership30
 * @property {string} store_id -
 * @property {string} tenant_id -
 */
/**
 * @typedef Platform30
 * @property {string} name -
 * @property {string} type -
 */
/**
 * @typedef Store30
 * @property {string} domain -
 */
/**
 * @typedef Version30
 * @property {number} major -
 * @property {number} minor -
 * @property {number} patch -
 */
/**
 * @typedef Meta30
 * @property {Ownership30} ownership -
 * @property {Platform30} platform -
 * @property {Store30} store -
 * @property {Version30} version -
 */
/**
 * @typedef UserPasswordResetCompleted
 * @property {Meta30} meta -
 * @property {string} [user_display_name] -
 * @property {string} [user_email] -
 * @property {string} [user_first_name] -
 * @property {string} user_id -
 * @property {string} [user_last_name] -
 * @property {string} [user_phone] -
 */
/**
 * @typedef Ownership31
 * @property {string} store_id -
 * @property {string} tenant_id -
 */
/**
 * @typedef Platform31
 * @property {string} name -
 * @property {string} type -
 */
/**
 * @typedef Store31
 * @property {string} domain -
 */
/**
 * @typedef Version31
 * @property {number} major -
 * @property {number} minor -
 * @property {number} patch -
 */
/**
 * @typedef Meta31
 * @property {Ownership31} ownership -
 * @property {Platform31} platform -
 * @property {Store31} store -
 * @property {Version31} version -
 */
/**
 * @typedef UserPasswordResetStarted
 * @property {string} email -
 * @property {Meta31} meta -
 */
/**
 * @typedef Ownership32
 * @property {string} store_id -
 * @property {string} tenant_id -
 */
/**
 * @typedef Platform32
 * @property {string} name -
 * @property {string} type -
 */
/**
 * @typedef Store32
 * @property {string} domain -
 */
/**
 * @typedef Version32
 * @property {number} major -
 * @property {number} minor -
 * @property {number} patch -
 */
/**
 * @typedef Meta32
 * @property {Ownership32} ownership -
 * @property {Platform32} platform -
 * @property {Store32} store -
 * @property {Version32} version -
 */
/**
 * @typedef UserSignedIn
 * @property {Meta32} meta -
 * @property {string} [user_display_name] -
 * @property {string} [user_email] -
 * @property {string} [user_first_name] -
 * @property {string} user_id -
 * @property {string} [user_last_name] -
 * @property {string} [user_phone] -
 */
/**
 * @typedef Ownership33
 * @property {string} store_id -
 * @property {string} tenant_id -
 */
/**
 * @typedef Platform33
 * @property {string} name -
 * @property {string} type -
 */
/**
 * @typedef Store33
 * @property {string} domain -
 */
/**
 * @typedef Version33
 * @property {number} major -
 * @property {number} minor -
 * @property {number} patch -
 */
/**
 * @typedef Meta33
 * @property {Ownership33} ownership -
 * @property {Platform33} platform -
 * @property {Store33} store -
 * @property {Version33} version -
 */
/**
 * @typedef UserSignedOut
 * @property {Meta33} meta -
 * @property {string} [user_display_name] -
 * @property {string} [user_email] -
 * @property {string} [user_first_name] -
 * @property {string} user_id -
 * @property {string} [user_last_name] -
 * @property {string} [user_phone] -
 */
/**
 * @typedef Ownership34
 * @property {string} store_id -
 * @property {string} tenant_id -
 */
/**
 * @typedef Platform34
 * @property {string} name -
 * @property {string} type -
 */
/**
 * @typedef Store34
 * @property {string} domain -
 */
/**
 * @typedef Version34
 * @property {number} major -
 * @property {number} minor -
 * @property {number} patch -
 */
/**
 * @typedef Meta34
 * @property {Ownership34} ownership -
 * @property {Platform34} platform -
 * @property {Store34} store -
 * @property {Version34} version -
 */
/**
 * @typedef UserSignedUp
 * @property {Meta34} meta -
 * @property {string} [referral_identifier_id] -
 * @property {string} signup_type -
 * @property {string} [user_display_name] -
 * @property {string} [user_email] -
 * @property {string} [user_first_name] -
 * @property {string} user_id -
 * @property {string} [user_last_name] -
 * @property {string} [user_phone] -
 */
/**
 * @typedef Ownership35
 * @property {string} store_id -
 * @property {string} tenant_id -
 */
/**
 * @typedef Platform35
 * @property {string} name -
 * @property {string} type -
 */
/**
 * @typedef Store35
 * @property {string} domain -
 */
/**
 * @typedef Version35
 * @property {number} major -
 * @property {number} minor -
 * @property {number} patch -
 */
/**
 * @typedef Meta35
 * @property {Ownership35} ownership -
 * @property {Platform35} platform -
 * @property {Store35} store -
 * @property {Version35} version -
 */
/**
 * @typedef VariantClicked
 * @property {Meta35} meta -
 * @property {string} [order_id] -
 * @property {string} [order_name] -
 * @property {string} [user_display_name] -
 * @property {string} [user_email] -
 * @property {string} [user_first_name] -
 * @property {string} [user_id] -
 * @property {string} [user_last_name] -
 * @property {string} [user_phone] -
 * @property {string} [variant_description] -
 * @property {string} variant_id -
 * @property {string} variant_image_url -
 * @property {string} variant_name -
 * @property {string} variant_sku -
 */
/**
 * User viewed their shopping cart
 *
 * @param {CartViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export const cartViewed = (props, options, callback) => {
    const a = analytics();
    if (a) {
        a.track('Cart Viewed', props || {}, withTypewriterContext(options), callback);
    }
}
/**
 * User completed the checkout process
 *
 * @param {CheckoutCompleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export const checkoutCompleted = (props, options, callback) => {
    const a = analytics();
    if (a) {
        a.track('Checkout Completed', props || {}, withTypewriterContext(options), callback);
    }
}
/**
 * User initiated the checkout process
 *
 * @param {CheckoutStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export const checkoutStarted = (props, options, callback) => {
    const a = analytics();
    if (a) {
        a.track('Checkout Started', props || {}, withTypewriterContext(options), callback);
    }
}
/**
 * User clicked on a product collection
 *
 * @param {CollectionClicked} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export const collectionClicked = (props, options, callback) => {
    const a = analytics();
    if (a) {
        a.track('Collection Clicked', props || {}, withTypewriterContext(options), callback);
    }
}
/**
 * Coupon was applied on a user’s shopping cart or order
 *
 * @param {CouponApplied} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export const couponApplied = (props, options, callback) => {
    const a = analytics();
    if (a) {
        a.track('Coupon Applied', props || {}, withTypewriterContext(options), callback);
    }
}
/**
 * Coupon was denied from a user’s shopping cart or order
 *
 * @param {CouponDenied} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export const couponDenied = (props, options, callback) => {
    const a = analytics();
    if (a) {
        a.track('Coupon Denied', props || {}, withTypewriterContext(options), callback);
    }
}
/**
 * User entered a coupon on a shopping cart or order
 *
 * @param {CouponEntered} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export const couponEntered = (props, options, callback) => {
    const a = analytics();
    if (a) {
        a.track('Coupon Entered', props || {}, withTypewriterContext(options), callback);
    }
}
/**
 * User removed a coupon from a cart or order
 *
 * @param {CouponRemoved} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export const couponRemoved = (props, options, callback) => {
    const a = analytics();
    if (a) {
        a.track('Coupon Removed', props || {}, withTypewriterContext(options), callback);
    }
}
/**
 * User entered an email address into a form
 *
 * @param {EmailCaptured} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export const emailCaptured = (props, options, callback) => {
    const a = analytics();
    if (a) {
        a.track('Email Captured', props || {}, withTypewriterContext(options), callback);
    }
}
/**
 * Gift card was applied on a user’s shopping cart or order
 *
 * @param {GiftCardApplied} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export const giftCardApplied = (props, options, callback) => {
    const a = analytics();
    if (a) {
        a.track('Gift Card Applied', props || {}, withTypewriterContext(options), callback);
    }
}
/**
 * Gift card was denied from a user’s shopping cart or order
 *
 * @param {GiftCardDenied} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export const giftCardDenied = (props, options, callback) => {
    const a = analytics();
    if (a) {
        a.track('Gift Card Denied', props || {}, withTypewriterContext(options), callback);
    }
}
/**
 * User entered a gift card on a cart or order
 *
 * @param {GiftCardEntered} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export const giftCardEntered = (props, options, callback) => {
    const a = analytics();
    if (a) {
        a.track('Gift Card Entered', props || {}, withTypewriterContext(options), callback);
    }
}
/**
 * User removed a gift card from a cart or order
 *
 * @param {GiftCardRemoved} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export const giftCardRemoved = (props, options, callback) => {
    const a = analytics();
    if (a) {
        a.track('Gift Card Removed', props || {}, withTypewriterContext(options), callback);
    }
}
/**
 * User clicked on a navigation link
 *
 * @param {NavigationClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export const navigationClicked = (props, options, callback) => {
    const a = analytics();
    if (a) {
        a.track('Navigation Clicked', props || {}, withTypewriterContext(options), callback);
    }
}
/**
 * User completed the order
 *
 * @param {OrderCompleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export const orderCompleted = (props, options, callback) => {
    const a = analytics();
    if (a) {
        a.track('Order Completed', props || {}, withTypewriterContext(options), callback);
    }
}
/**
 * User added a product to their shopping cart
 *
 * @param {ProductAdded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export const productAdded = (props, options, callback) => {
    const a = analytics();
    if (a) {
        a.track('Product Added', props || {}, withTypewriterContext(options), callback);
    }
}
/**
 * User clicked on a product
 *
 * @param {ProductClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export const productClicked = (props, options, callback) => {
    const a = analytics();
    if (a) {
        a.track('Product Clicked', props || {}, withTypewriterContext(options), callback);
    }
}
/**
 * User clicked on a specific tab, section, panel, etc on the PDP
 *
 * @param {ProductDetailsClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export const productDetailsClicked = (props, options, callback) => {
    const a = analytics();
    if (a) {
        a.track('Product Details Clicked', props || {}, withTypewriterContext(options), callback);
    }
}
/**
 * User filtered a product list or category
 *
 * @param {ProductListFiltered} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export const productListFiltered = (props, options, callback) => {
    const a = analytics();
    if (a) {
        a.track('Product List Filtered', props || {}, withTypewriterContext(options), callback);
    }
}
/**
 * User viewed a product list or category
 *
 * @param {ProductListViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export const productListViewed = (props, options, callback) => {
    const a = analytics();
    if (a) {
        a.track('Product List Viewed', props || {}, withTypewriterContext(options), callback);
    }
}
/**
 * User removed a product from their shopping cart
 *
 * @param {ProductRemoved} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export const productRemoved = (props, options, callback) => {
    const a = analytics();
    if (a) {
        a.track('Product Removed', props || {}, withTypewriterContext(options), callback);
    }
}
/**
 * User submitted a review for a product
 *
 * @param {ProductReviewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export const productReviewed = (props, options, callback) => {
    const a = analytics();
    if (a) {
        a.track('Product Reviewed', props || {}, withTypewriterContext(options), callback);
    }
}
/**
 * User updated a product in their shopping cart
 *
 * @param {ProductUpdated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export const productUpdated = (props, options, callback) => {
    const a = analytics();
    if (a) {
        a.track('Product Updated', props || {}, withTypewriterContext(options), callback);
    }
}
/**
 * User viewed a product
 *
 * @param {ProductViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export const productViewed = (props, options, callback) => {
    const a = analytics();
    if (a) {
        a.track('Product Viewed', props || {}, withTypewriterContext(options), callback);
    }
}
/**
 * User clicked on promotion
 *
 * @param {PromotionClicked} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export const promotionClicked = (props, options, callback) => {
    const a = analytics();
    if (a) {
        a.track('Promotion Clicked', props || {}, withTypewriterContext(options), callback);
    }
}
/**
 * User viewed a promotion
 *
 * @param {PromotionViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export const promotionViewed = (props, options, callback) => {
    const a = analytics();
    if (a) {
        a.track('Promotion Viewed', props || {}, withTypewriterContext(options), callback);
    }
}
/**
 * User requested to be notified when a product is back in stock
 *
 * @param {StockRequestCreated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export const stockRequestCreated = (props, options, callback) => {
    const a = analytics();
    if (a) {
        a.track('Stock Request Created', props || {}, withTypewriterContext(options), callback);
    }
}
/**
 * User added an address to their profile
 *
 * @param {UserAddressAdded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export const userAddressAdded= (props, options, callback) => {
    const a = analytics();
    if (a) {
        a.track('User Address Added', props || {}, withTypewriterContext(options), callback);
    }
}
/**
 * User removed an address from their profile
 *
 * @param {UserAddressRemoved} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export const userAddressRemoved= (props, options, callback) => {
    const a = analytics();
    if (a) {
        a.track('User Address Removed', props || {}, withTypewriterContext(options), callback);
    }
}
/**
 * User updated an address in their profile
 *
 * @param {UserAddressUpdated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export const userAddressUpdated = (props, options, callback) => {
    const a = analytics();
    if (a) {
        a.track('User Address Updated', props || {}, withTypewriterContext(options), callback);
    }
}
/**
 * User completed the password reset process
 *
 * @param {UserPasswordResetCompleted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export const userPasswordResetCompleted = (props, options, callback) => {
    const a = analytics();
    if (a) {
        a.track('User Password Reset Completed', props || {}, withTypewriterContext(options), callback);
    }
}
/**
 * User initiated the password reset process
 *
 * @param {UserPasswordResetStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export const userPasswordResetStarted = (props, options, callback) => {
    const a = analytics();
    if (a) {
        a.track('User Password Reset Started', props || {}, withTypewriterContext(options), callback);
    }
}
/**
 * User signed into their account
 *
 * @param {UserSignedIn} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export const userSignedIn = (props, options, callback) => {
    const a = analytics();
    if (a) {
        a.track('User Signed In', props || {}, withTypewriterContext(options), callback);
    }
}
/**
 * User signed out from their account
 *
 * @param {UserSignedOut} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export const userSignedOut = (props, options, callback) => {
    const a = analytics();
    if (a) {
        a.track('User Signed Out', props || {}, withTypewriterContext(options), callback);
    }
}
/**
 * User signed up for an account
 *
 * @param {UserSignedUp} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export const userSignedUp = (props, options, callback) => {
    const a = analytics();
    if (a) {
        a.track('User Signed Up', props || {}, withTypewriterContext(options), callback);
    }
}
/**
 * User clicked on a product variation
 *
 * @param {VariantClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export const variantClicked = (props, options, callback) => {
    const a = analytics();
    if (a) {
        a.track('Variant Clicked', props || {}, withTypewriterContext(options), callback);
    }
}
const clientAPI = {
    /**
     * Updates the run-time configuration of this Typewriter client.
     *
     * @param {TypewriterOptions} options - the options to upsert
     *
     * @typedef {Object} TypewriterOptions
     * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
     * 		calls are forwarded on to. Defaults to window.analytics.
     * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
     * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
     * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
     * 		will be logged to stderr.
     */
    setTypewriterOptions: setTypewriterOptions,
    /**
     * User viewed their shopping cart
     *
     * @param {CartViewed} props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 		call is fired.
     */
    cartViewed: cartViewed,
    /**
     * User completed the checkout process
     *
     * @param {CheckoutCompleted} props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 		call is fired.
     */
    checkoutCompleted: checkoutCompleted,
    /**
     * User initiated the checkout process
     *
     * @param {CheckoutStarted} props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 		call is fired.
     */
    checkoutStarted: checkoutStarted,
    /**
     * User clicked on a product collection
     *
     * @param {CollectionClicked} [props] - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 		call is fired.
     */
    collectionClicked: collectionClicked,
    /**
     * Coupon was applied on a user’s shopping cart or order
     *
     * @param {CouponApplied} [props] - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 		call is fired.
     */
    couponApplied: couponApplied,
    /**
     * Coupon was denied from a user’s shopping cart or order
     *
     * @param {CouponDenied} [props] - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 		call is fired.
     */
    couponDenied: couponDenied,
    /**
     * User entered a coupon on a shopping cart or order
     *
     * @param {CouponEntered} [props] - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 		call is fired.
     */
    couponEntered: couponEntered,
    /**
     * User removed a coupon from a cart or order
     *
     * @param {CouponRemoved} [props] - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 		call is fired.
     */
    couponRemoved: couponRemoved,
    /**
     * User entered an email address into a form
     *
     * @param {EmailCaptured} props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 		call is fired.
     */
    emailCaptured: emailCaptured,
    /**
     * Gift card was applied on a user’s shopping cart or order
     *
     * @param {GiftCardApplied} [props] - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 		call is fired.
     */
    giftCardApplied: giftCardApplied,
    /**
     * Gift card was denied from a user’s shopping cart or order
     *
     * @param {GiftCardDenied} props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 		call is fired.
     */
    giftCardDenied: giftCardDenied,
    /**
     * User entered a gift card on a cart or order
     *
     * @param {GiftCardEntered} props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 		call is fired.
     */
    giftCardEntered: giftCardEntered,
    /**
     * User removed a gift card from a cart or order
     *
     * @param {GiftCardRemoved} [props] - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 		call is fired.
     */
    giftCardRemoved: giftCardRemoved,
    /**
     * User clicked on a navigation link
     *
     * @param {NavigationClicked} props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 		call is fired.
     */
    navigationClicked: navigationClicked,
    /**
     * User completed the order
     *
     * @param {OrderCompleted} props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 		call is fired.
     */
    orderCompleted: orderCompleted,
    /**
     * User added a product to their shopping cart
     *
     * @param {ProductAdded} props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 		call is fired.
     */
    productAdded: productAdded,
    /**
     * User clicked on a product
     *
     * @param {ProductClicked} props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 		call is fired.
     */
    productClicked: productClicked,
    /**
     * User clicked on a specific tab, section, panel, etc on the PDP
     *
     * @param {ProductDetailsClicked} props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 		call is fired.
     */
    productDetailsClicked: productDetailsClicked,
    /**
     * User filtered a product list or category
     *
     * @param {ProductListFiltered} props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 		call is fired.
     */
    productListFiltered: productListFiltered,
    /**
     * User viewed a product list or category
     *
     * @param {ProductListViewed} props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 		call is fired.
     */
    productListViewed: productListViewed,
    /**
     * User removed a product from their shopping cart
     *
     * @param {ProductRemoved} props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 		call is fired.
     */
    productRemoved: productRemoved,
    /**
     * User submitted a review for a product
     *
     * @param {ProductReviewed} props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 		call is fired.
     */
    productReviewed: productReviewed,
    /**
     * User updated a product in their shopping cart
     *
     * @param {ProductUpdated} props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 		call is fired.
     */
    productUpdated: productUpdated,
    /**
     * User viewed a product
     *
     * @param {ProductViewed} props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 		call is fired.
     */
    productViewed: productViewed,
    /**
     * User clicked on promotion
     *
     * @param {PromotionClicked} [props] - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 		call is fired.
     */
    promotionClicked: promotionClicked,
    /**
     * User viewed a promotion
     *
     * @param {PromotionViewed} [props] - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 		call is fired.
     */
    promotionViewed: promotionViewed,
    /**
     * User requested to be notified when a product is back in stock
     *
     * @param {StockRequestCreated} props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 		call is fired.
     */
    stockRequestCreated: stockRequestCreated,
    /**
     * User added an address to their profile
     *
     * @param {UserAddressAdded} props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 		call is fired.
     */
    userAddressAdded: userAddressAdded,
    /**
     * User removed an address from their profile
     *
     * @param {UserAddressRemoved} props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 		call is fired.
     */
    userAddressRemoved: userAddressRemoved,
    /**
     * User updated an address in their profile
     *
     * @param {UserAddressUpdated} props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 		call is fired.
     */
    userAddressUpdated: userAddressUpdated,
    /**
     * User completed the password reset process
     *
     * @param {UserPasswordResetCompleted} [props] - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 		call is fired.
     */
    userPasswordResetCompleted: userPasswordResetCompleted,
    /**
     * User initiated the password reset process
     *
     * @param {UserPasswordResetStarted} props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 		call is fired.
     */
    userPasswordResetStarted: userPasswordResetStarted,
    /**
     * User signed into their account
     *
     * @param {UserSignedIn} [props] - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 		call is fired.
     */
    userSignedIn: userSignedIn,
    /**
     * User signed out from their account
     *
     * @param {UserSignedOut} [props] - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 		call is fired.
     */
    userSignedOut: userSignedOut,
    /**
     * User signed up for an account
     *
     * @param {UserSignedUp} [props] - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 		call is fired.
     */
    userSignedUp: userSignedUp,
    /**
     * User clicked on a product variation
     *
     * @param {VariantClicked} props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 		call is fired.
     */
    variantClicked: variantClicked
};
export default new Proxy(clientAPI, {
    get: function get(target, method) {
        if (typeof method === 'string' && target.hasOwnProperty(method)) {
            return target[method];
        }
        return function () {
            console.warn("\u26A0\uFE0F  You made an analytics call (".concat(String(method), ") that can't be found. Either:\n    a) Re-generate your typewriter client: `npx typewriter`\n    b) Add it to your Tracking Plan: https://app.segment.com/chord-commerce/protocols/tracking-plans/rs_1s7xbLEMh8l6JXRzFL5nMHeV2Q6"));
            const a = analytics();
            if (a) {
                a.track('Unknown Analytics Call Fired', {
                    method: method
                }, withTypewriterContext());
            }
        };
    }
});
