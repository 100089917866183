/* eslint-disable */
import { createRunQuery, formatVariantId, formatCustomerId, runQueryPluckAndTranslate, transformGraphCheckout, transformGraphCustomer, transformGraphProductWithVariants, transformGraphVariant } from './utils';
import queries from './queries';
import OMSError from './error';

export { default as OMSError } from './error';

export const createOMSClient = function createOMSClient(config) {
    const runQuery = createRunQuery(config);
    const runCheckoutQuery = function runCheckoutQuery(query, variables, pathToMutation) {
        return runQueryPluckAndTranslate(runQuery, query, variables, pathToMutation + '.checkout', pathToMutation + '.checkoutUserErrors', transformGraphCheckout);
    };
    const runQueryAndPluck = function runQueryAndPluck(query, variables, pathToData, pathToErrors) {
        return runQueryPluckAndTranslate(runQuery, query, variables, pathToData, pathToErrors);
    };

    const client = {
        associateOrderWithUser: function associateOrderWithUser(identifier, customerAccessToken) {
            const query = queries.checkoutCustomerAssociateV2;
            const variables = {
                checkoutId: identifier,
                customerAccessToken: customerAccessToken
            };
            const pathToMutation = 'data.data.checkoutCustomerAssociateV2';
            return runCheckoutQuery(query, variables, pathToMutation);
        },
        createOrder: function createOrder() {
            const query = queries.checkoutCreate;
            const variables = { input: {} };
            const pathToMutation = 'data.data.checkoutCreate';
            return runCheckoutQuery(query, variables, pathToMutation);
        },
        createUser: function createUser(input) {
            const query = queries.customerCreate;
            const variables = { input: input };
            const pathToMutation = 'data.data.customerCreate';
            const pathToData = pathToMutation + '.customer';
            const pathToErrors = pathToMutation + '.customerUserErrors';
            return runQueryAndPluck(query, variables, pathToData, pathToErrors);
        },
        getUser: function getUser(customerAccessToken) {
            const query = queries.customerQuery;
            const variables = { customerAccessToken: customerAccessToken };
            const path = 'data.data.customer';
            return new Promise(function(resolve, reject) {
                runQueryAndPluck(query, variables, path)
                    .then(function(rawResponse) {
                        if (!rawResponse) {
                            reject(new Error('An unexpected error occurred.'));
                        } else {
                            resolve(transformGraphCustomer(rawResponse));
                        }
                    })
                    .catch(reject);
            });
        },
        createUserToken: function createUserToken(credentials) {
            const query = queries.customerAccessTokenCreate;
            const variables = { input: credentials };
            const pathToMutation = 'data.data.customerAccessTokenCreate';
            const pathToData = pathToMutation + '.customerAccessToken';
            const pathToErrors = pathToMutation + '.customerUserErrors';
            return runQueryAndPluck(query, variables, pathToData, pathToErrors);
        },
        deleteUserToken: function deleteUserToken(customerAccessToken) {
            const query = queries.customerAccessTokenDelete;
            const variables = { customerAccessToken: customerAccessToken };
            const pathToMutation = 'data.data.customerAccessTokenDelete';
            const pathToData = pathToMutation + '.deletedAccessToken';
            const pathToErrors = pathToMutation + '.userErrors';
            return new Promise(function(resolve, reject) {
                runQueryAndPluck(query, variables, pathToData, pathToErrors)
                    .then(resolve)
                    .catch(reject);
            });
        },
        createUserAddress: function createUserAddress(customerAccessToken, address) {
            const query = queries.customerAddressCreate;
            const variables = { customerAccessToken: customerAccessToken, address: address };
            const pathToMutation = 'data.data.customerAddressCreate';
            const pathToData = pathToMutation + '.customerAddress';
            const pathToErrors = pathToMutation + '.customerUserErrors';
            return new Promise(function(resolve, reject) {
                runQueryAndPluck(query, variables, pathToData, pathToErrors)
                    .then(resolve)
                    .catch(reject);
            });
        },
        updateUserAddress: function updateUserAddress(customerAccessToken, addressId, address) {
            const query = queries.customerAddressUpdate;
            const variables = { customerAccessToken: customerAccessToken, id: addressId, address: address };
            const pathToMutation = 'data.data.customerAddressUpdate';
            const pathToData = pathToMutation + '.customerAddress';
            const pathToErrors = pathToMutation + '.customerUserErrors';
            return new Promise(function(resolve, reject) {
                runQueryAndPluck(query, variables, pathToData, pathToErrors)
                    .then(resolve)
                    .catch(reject);
            });
        },
        updateUserDefaultAddress: function updateUserDefaultAddress(customerAccessToken, addressId) {
            const query = queries.customerDefaultAddressUpdate;
            const variables = { customerAccessToken: customerAccessToken, addressId: addressId };
            const pathToMutation = 'data.data.customerDefaultAddressUpdate';
            const pathToData = pathToMutation + '.customer';
            const pathToErrors = pathToMutation + '.customerUserErrors';
            return new Promise(function(resolve, reject) {
                runQueryAndPluck(query, variables, pathToData, pathToErrors)
                    .then(function(rawResponse) {
                        if (!rawResponse) {
                            reject(new Error('An unexpected error occurred.'));
                        } else {
                            resolve(transformGraphCustomer(rawResponse));
                        }
                    })
                    .catch(reject);
            });
        },
        removeUserAddress: function removeUserAddress(customerAccessToken, addressId) {
            const query = queries.customerAddressDelete;
            const variables = { customerAccessToken: customerAccessToken, id: addressId };
            const pathToMutation = 'data.data.customerAddressDelete';
            const pathToErrors = pathToMutation + '.customerUserErrors';
            return new Promise(function(resolve, reject) {
                runQueryAndPluck(query, variables, undefined, pathToErrors)
                    .then(resolve)
                    .catch(reject);
            });
        },
        recoverUser: function recoverUser(email) {
            const query = queries.customerRecover;
            const variables = { email: email };
            const pathToMutation = 'data.data.customerRecover';
            const pathToErrors = pathToMutation + '.userErrors';
            return new Promise(function(resolve, reject) {
                runQueryAndPluck(query, variables, undefined, pathToErrors)
                    .then(resolve)
                    .catch(reject);
            });
        },
        renewUserToken: function renewUserToken(customerAccessToken) {
            const query = queries.customerAccessTokenRenew;
            const variables = { customerAccessToken: customerAccessToken };
            const pathToMutation = 'data.data.customerAccessTokenRenew';
            const pathToData = pathToMutation + '.customerAccessToken';
            const pathToErrors = pathToMutation + '.customerUserErrors';
            return new Promise(function(resolve, reject) {
                runQueryAndPluck(query, variables, pathToData, pathToErrors)
                    .then(resolve)
                    .catch(reject);
            });
        },
        resetUserPassword: function resetUserPassword(_ref) {
            const customerId = _ref.customerId, password = _ref.password, resetToken = _ref.resetToken;
            const query = queries.customerReset;
            const variables = {
                id: formatCustomerId(customerId),
                input: { password: password, resetToken: resetToken }
            };
            const pathToMutation = 'data.data.customerReset';
            const pathToData = pathToMutation + '.customerAccessToken';
            const pathToErrors = pathToMutation + '.customerUserErrors';
            return new Promise(function(resolve, reject) {
                runQueryAndPluck(query, variables, pathToData, pathToErrors)
                    .then(resolve)
                    .catch(reject);
            });
        },
        getOrder: function getOrder(identifier) {
            const query = queries.getCheckout;
            const variables = { id: identifier };
            const path = 'data.data.node';
            return new Promise(function(resolve, reject) {
                runQueryAndPluck(query, variables, path)
                    .then(function(rawResponse) {
                        if (!rawResponse) {
                            reject(new OMSError('An unexpected error occurred.'));
                        } else {
                            resolve(transformGraphCheckout(rawResponse));
                        }
                    })
                    .catch(reject);
            });
        },
        getProduct: function getProduct(handle) {
            const query = queries.getProductByHandle;
            const variables = { handle: handle };
            const path = 'data.data.productByHandle';
            return new Promise(function(resolve, reject) {
                runQueryAndPluck(query, variables, path)
                    .then(function(rawResponse) {
                        if (!rawResponse) {
                            reject(new OMSError('An unexpected error occurred.'));
                        } else {
                            resolve(transformGraphProductWithVariants(rawResponse));
                        }
                    })
                    .catch(reject);
            });
        },
        getVariant: function getVariant(identifier) {
            const query = queries.getVariantById;
            const variables = { id: identifier };
            const path = 'data.data.variant';
            return new Promise(function(resolve, reject) {
                runQueryAndPluck(query, variables, path)
                    .then(function(rawResponse) {
                        if (!rawResponse) {
                            reject(new OMSError('An unexpected error occurred.'));
                        } else {
                            resolve(transformGraphVariant(rawResponse));
                        }
                    })
                    .catch(reject);
            });
        },
        updateOrder: function updateOrder(identifier, payload) {
            const query = queries.checkoutAttributesUpdateV2;
            const variables = { checkoutId: identifier, input: payload };
            const pathToMutation = 'data.data.checkoutAttributesUpdateV2';
            return runCheckoutQuery(query, variables, pathToMutation);
        },
        addOrderItem: function addOrderItem(identifier, _ref2) {
            const quantity = _ref2.quantity, variantId = _ref2.variantId, customAttributes = _ref2.customAttributes;
            const query = queries.checkoutLineItemsAdd;
            const variables = {
                checkoutId: identifier,
                lineItems: [{ quantity: quantity, variantId: formatVariantId(variantId), customAttributes: customAttributes }]
            };
            const pathToMutation = 'data.data.checkoutLineItemsAdd';
            return runCheckoutQuery(query, variables, pathToMutation);
        },
        removeOrderItem: function removeOrderItem(identifier, lineItemId) {
            const query = queries.checkoutLineItemsRemove;
            const variables = { checkoutId: identifier, lineItemIds: [lineItemId] };
            const pathToMutation = 'data.data.checkoutLineItemsRemove';
            return runCheckoutQuery(query, variables, pathToMutation);
        },
        updateOrderItem: function updateOrderItem(identifier, data) {
            const lineItemId = data.lineItemId, quantity = data.quantity, customAttributes = data.customAttributes;
            const query = queries.checkoutLineItemsUpdate;
            const variables = {
                checkoutId: identifier,
                lineItems: [{ id: lineItemId, quantity: quantity, customAttributes: customAttributes }]
            };
            const pathToMutation = 'data.data.checkoutLineItemsUpdate';
            return runCheckoutQuery(query, variables, pathToMutation);
        },
        // ... [other methods] ...

        addPromoCode: function(identifier, promoCode) {
            const query = queries.checkoutDiscountCodeApplyV2;
            const variables = {
                discountCode: promoCode,
                checkoutId: identifier
            };
            const pathToMutation = 'data.data.checkoutDiscountCodeApplyV2';
            return runCheckoutQuery(query, variables, pathToMutation);
        },

        removePromoCode: function(identifier) {
            const query = queries.checkoutDiscountCodeRemove;
            const variables = {
                checkoutId: identifier
            };
            const pathToMutation = 'data.data.checkoutDiscountCodeRemove';
            return runCheckoutQuery(query, variables, pathToMutation);
        },

        applyGiftCard: function(identifier, giftCardCode) {
            const query = queries.checkoutGiftCardsAppend;
            const variables = {
                checkoutId: identifier,
                giftCardCodes: [giftCardCode]
            };
            const pathToMutation = "data.data.checkoutGiftCardsAppend";
            return runCheckoutQuery(query, variables, pathToMutation);
        },

        removeGiftCard: function(identifier, appliedGiftCardId) {
            const query = queries.checkoutGiftCardRemoveV2;
            const variables = {
                checkoutId: identifier,
                appliedGiftCardId: appliedGiftCardId
            };
            const pathToMutation = 'data.data.checkoutGiftCardRemoveV2';
            return runCheckoutQuery(query, variables, pathToMutation);
        },

        checkout: function(identifier, customerAccessToken) {
            return client.getOrder(identifier).then(function(checkout) {
                const customAttributes = [];
                // Additional logic for customAttributes...
                const checkoutIsReady = checkout.ready && checkout.webUrl;
                const windowIsAvailable = typeof window !== 'undefined' && window.location && window.location.href;
                if (!checkoutIsReady || !windowIsAvailable) {
                    return;
                }
                if (customerAccessToken && config.multipassUrl) {
                    return fetch(config.multipassUrl, {
                        method: 'POST',
                        body: JSON.stringify({
                            token: customerAccessToken,
                            return_to: checkout.webUrl
                        })
                    })
                        .then(function(response) {
                            return response.json();
                        })
                        .then(function(data) {
                            const token = data.token;
                            window.location.href = `https://${config.domain}/account/login/multipass/${token}`;
                        })
                        .catch(function(error) {
                            console.error('Multipass session could not be generated.');
                            window.location.href = checkout.webUrl;
                        });
                } else {
                    window.location.href = checkout.webUrl;
                }
            });
        },

        getVariantAvailability: function(identifier) {
            const query = queries.getVariantAvailability;
            const variables = {
                id: identifier
            };
            const path = 'data.data.variant';
            return runQueryAndPluck(query, variables, path)
                .then(function(rawResponse) {
                    if (!rawResponse) {
                        throw new OMSError('An unexpected error occurred.');
                    }
                    return rawResponse;
                });
        }
    };
    return client;
};
